import {useEffect, useMemo} from "react";
import {debounce} from "lodash";

/**
 * Debounce a callback, delaying calls to it until wait milliseconds have elapsed since the last time the debounced function was invoked
 * @param callback - the callback to debounce
 * @param wait - how long to wait in ms. If undefined, the callback will not be debounced
 */
export function useDebounce<T extends (...args: any[]) => any>(callback: T, wait?: number) {
    const debouncedCallback = useMemo(() => {
        if (wait !== undefined) {
            return debounce(callback, wait);
        }
        return callback;
    }, [callback, wait]);

    useEffect(() => () => {
        // clear any debounced events on unmount
        if ("cancel" in debouncedCallback) {
            debouncedCallback.cancel();
        }
    }, [debouncedCallback]);
    return debouncedCallback;
}