import {configureStore} from "@reduxjs/toolkit";
import {accountSlice} from "./slices/accountSlice";
import {lobbyApi} from "./apis/lobby-api";
import {adminApi} from "./apis/admin-api";
import {configSlice} from "./slices/configSlice";
import {lobbySlice} from "./slices/lobbySlice";
import {createPersistLobbySettingsMiddleware} from "./middleware/perist-lobby-state-middleware";
import {apiErrorHandlingMiddleware} from './middleware/api-error-handling-middleware';
import {editSlice} from "./slices/edit-slice";

export const store = configureStore({
    reducer : {
        [accountSlice.name]: accountSlice.reducer,
        [lobbySlice.name]: lobbySlice.reducer,
        [configSlice.name]: configSlice.reducer,
        [lobbyApi.reducerPath]: lobbyApi.reducer,
        [adminApi.reducerPath]: adminApi.reducer,
        [editSlice.name] : editSlice.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware()
            .concat(
                createPersistLobbySettingsMiddleware([
                    "customer", "server", "realPlay", "affiliate", "platform",
                    "launchMode", "serverType", "favourites", "sortAscending", "languageOverride"
                ]),
                lobbyApi.middleware,
                adminApi.middleware,
                apiErrorHandlingMiddleware,
            )
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch;
