import {TextField, TextFieldProps} from '@mui/material';
import {ChangeEvent, FunctionComponent} from 'react';
import {useField} from 'formik';

export type NumberFieldProps = Omit<TextFieldProps, "onChange" | "onBlur" | "value" | "name"> & {
    name: string,
    treatZeroAsNull?: boolean,
};
/**
 * MUI {@link TextField} set up to handle numeric values.
 *
 * The type="number" input isn't recommended for numeric input of this kind.
 *
 * This is a [Formik]{@link https://formik.org/docs/overview} Field
 */
export const NumberField: FunctionComponent<NumberFieldProps> = ({name, treatZeroAsNull, ...props}) => {
    const [field, meta, helpers] = useField<number | null>(name);

    const {value} = meta;
    const {setValue, setError} = helpers;

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        const val = e.currentTarget.value;
        const numVal = +val;
        if (val?.trim() === "" || (treatZeroAsNull && numVal === 0)) {
            setValue(null);
        } else if (Number.isNaN(numVal)) {
            setError("Value is not a number")
        } else {
            setValue(numVal);
        }
    }

    return (
        <TextField
            inputProps={{
                inputMode: "numeric",
                pattern: "[0-9]*"
            }}
            {...props}
            value={value ?? ""}
            onChange={onChange}
            error={(meta.touched && !!meta.error)}
            helperText={meta.touched && meta.error}
            onBlur={field.onBlur}
        />
    )
}
