import ExpandedGame from "../models/expanded-game";
import {FunctionComponent, useMemo} from "react";
import {useAppSelector} from "../store/hooks/root-hooks";
import {selectGameTabsConfig, TabsConfig} from "../store/slices/configSlice";
import {
    selectFavouriteGames,
} from "../store/slices/lobbySlice";
import {TabSet, TabDefinition} from "./common/tabs/TabSet";
import {GamesList} from "./GamesList";
import {Alert, styled} from "@mui/material";
import {useBreakpoints} from '../hooks/use-breakpoints';
import {useGetTabsDetailsQuery} from "../store/apis/lobby-api";
import {SlimLobbyTab} from "../models/lobby-tab";

interface LobbyTabDef {
    label: string;
    games: ExpandedGame[];
    noGamesText?: string;
}

export interface LobbyTabsProps {
    games: ExpandedGame[];
}

const defaultTabNames = {
    newGamesTabLabel: "New Games",
    otherGamesTabLabel: "Other Games",
    allGamesTabLabel: "All Games",
    favouriteGamesTabLabel: "Your Favourites",
}

const generateGameTabsTabs = (games: ExpandedGame[], favourites: number[], tabsConfig: TabsConfig = {}, tabsDetails: Record<SlimLobbyTab["tabId"], SlimLobbyTab> | undefined) => {
    const {
        newTabDays = -1,
        showFavouritesAsTab = true,
        showAllGamesTab = true,
        systemTabNamesOverride = {},
    } = tabsConfig;

    const {
        newGamesTabLabel = defaultTabNames.newGamesTabLabel,
        otherGamesTabLabel = defaultTabNames.otherGamesTabLabel,
        allGamesTabLabel = defaultTabNames.allGamesTabLabel,
        favouriteGamesTabLabel = defaultTabNames.favouriteGamesTabLabel,
    } = systemTabNamesOverride;
    const allTabs: LobbyTabDef[] = [];

    if (newTabDays >= 0) {
        const newGames: ExpandedGame[] = games.filter((g) => g.newUntil && g.newUntil >= new Date());
        allTabs.push({
            label: newGamesTabLabel,
            games: newGames,
        })
    }
    const tablessGames: ExpandedGame[] = [];
    const tabMap = games.reduce<Map<ExpandedGame["tabId"] | string, ExpandedGame[]>>((tabs, game) => {
        const {tabId} = game;
        if (tabId) {
            if (!tabs.has(tabId)) {
                tabs.set(tabId, []);
            }
            tabs.get(tabId)?.push(game);
        } else {
            tablessGames.push(game);
        }
        return tabs;
    }, new Map<ExpandedGame["tabId"] | string, ExpandedGame[]>());
    // add any games without a tab
    if (tablessGames.length) {
        tabMap.set(otherGamesTabLabel, []);
        tablessGames.forEach((g) => tabMap.get(otherGamesTabLabel)?.push(g));
    }
    tabMap.forEach((gamesInTab, tabKey) => {
        const isLabelMissing = tabKey === undefined || tabKey === null;
        const label = typeof tabKey === "number" ? (tabsDetails?.[tabKey]?.tabName ?? `Tab ${tabKey}`) : tabKey;
        allTabs.push({
            label: isLabelMissing ? "Missing Tab Name" : `${label}`,
            games: gamesInTab,
        })
    });

    if (showAllGamesTab) {
        allTabs.push({
            label: allGamesTabLabel,
            games,
        });
        if (tablessGames.length === games.length) {
            tabMap.delete(otherGamesTabLabel);
        }
    }
    if (showFavouritesAsTab) {
        allTabs.push({
            label: favouriteGamesTabLabel,
            games: games.filter((game) => favourites.includes(game.id)),
            noGamesText: "You have not added any games to your favourites yet!",
        })
    }
    return allTabs
        .filter((t) => t.games.length || t.noGamesText)
        .map(({label, games, noGamesText}) => ({
            label,
            content: (
                games.length
                    ? <GamesList games={games}/>
                    : (
                        <Alert
                            severity="info"
                            sx={{
                                marginTop: 2
                            }}
                        >
                            {noGamesText}
                        </Alert>
                    )
            )

        }))
};

const LobbyTabSet = styled(TabSet)({
    flex: "1 1 auto",
})

export const LobbyTabs: FunctionComponent<LobbyTabsProps> = ({games}) => {
    const tabsConfig = useAppSelector(selectGameTabsConfig);

    const favourites = useAppSelector(selectFavouriteGames);

    const {isNarrow} = useBreakpoints();

    const {data: tabsDetails/*, isLoading: areTabDetailsLoading*/} = useGetTabsDetailsQuery(games.reduce((ids: Array<SlimLobbyTab["tabId"]>, game) => {
        if(typeof game.tabId === "number") {
            ids.push(game.tabId);
        }

        return ids;
    }, []))

    const tabs: TabDefinition[] = useMemo(() =>
            generateGameTabsTabs(games, favourites, tabsConfig, tabsDetails),
        [games, favourites, tabsConfig, tabsDetails]);

    const allGamesTabIndex = useMemo(() =>
        tabs.findIndex((tab) =>
            tab.label === (tabsConfig?.systemTabNamesOverride?.allGamesTabLabel ?? defaultTabNames.allGamesTabLabel)
        ), [tabs, tabsConfig]);
    //const search = useAppSelector(selectLobbyGamesSearchString);

    const defaultTabIndex = allGamesTabIndex;
    return (
        <LobbyTabSet
            tabs={tabs}
            tabsProps={{
                variant: isNarrow ? "fullWidth" : undefined,
                centered: !isNarrow,
            }}
            defaultTabIndex={(defaultTabIndex ?? -1) >= 0 ? defaultTabIndex : undefined}
        />
    )
}
