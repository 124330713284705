import {createSelector} from "@reduxjs/toolkit";
import {selectConfigAdminEnabled, selectConfigAssetsRoot} from "./slices/configSlice";
import {
    selectCurrentUser,
    selectCurrentUserHasAdminAccess,
    selectCurrentUserLanguage,
    selectCurrentUserToken
} from "./slices/accountSlice";
import {
    selectGameLaunchUsesLoader,
    selectGameLaunchOptions,
    selectLobbyEnvironmentOptions,
    selectGameById
} from "./slices/lobbySlice";
import {getLaunchParamsForGame} from "../models/game";


export const selectShowAdmin = createSelector(
    selectConfigAdminEnabled,
    selectCurrentUserHasAdminAccess,
    (showAdmin, userIsAdmin) => showAdmin && userIsAdmin,
)

export const selectAbsoluteGameImagePath = (imgPath: string) => createSelector(
    selectConfigAssetsRoot,
    (assetRoot) => `${assetRoot}${imgPath.replace("\\\\", "/")}`
)

export const selectLaunchParamsForGameById = (id: number) => createSelector(
    selectCurrentUserLanguage,
    selectCurrentUserToken,
    selectGameById(id),
    selectLobbyEnvironmentOptions,
    selectGameLaunchOptions,
    selectGameLaunchUsesLoader,
    selectCurrentUser,
    (language, token, game, {customer}, {realPlay, platform, serverType, launchMode, affiliate, languageOverride, devFlag}, useLoader, user) => {
        if (!game || !customer || !platform || !serverType || !launchMode) {
            return "";
        }
        const transformedAffiliate = affiliate?.replace(/^content-/i, "") ?? "";
        // @ts-ignore
        return getLaunchParamsForGame(game, languageOverride ?? language ?? "en", token, customer, realPlay ?? false, platform, serverType, launchMode, transformedAffiliate, devFlag ?? false, useLoader, user)
    }
)

export const selectLaunchUrlForGameById = (id: number) => createSelector(
    selectGameById(id),
    selectGameLaunchUsesLoader,
    selectLaunchParamsForGameById(id),
    (game, useLoader, params) => {
        if (!game) {
            return undefined;
        }
        if (game.v3Launch) {
            return game.gamepath + params;
        }
        const baseUrl = useLoader ? game.loaderLaunchUrl : game.directLaunchUrl;
        return baseUrl + params;
    }
)


// direct standard params: platform, game, token, language, playmode, affiliate
// content standard params: platform, game, token, language, playmode, affiliate
// content hits loader.aspx
// dev lobby direct extras: serverType, customer, assetsName
// v3 dev launch hits start-game/develop/index.html?, and specifies the gameName, which should be the engine name (no BP_)

// lobby direct pubst
// https://blueprintrgspubst.blueprintgaming.com/Mobile/BP_MOBKingKongCash/index.html?platform=desktop&game=&token=37PoCZadVQxhqJtq6pr4CzugAGeB6AqfCQEpXHsODPzURiQKLb;david&language=ENG&playmode=real&affiliate=direct
// lobby content pubst (broken)
// https://rgslobby.blueprintgaming.com/?platform=desktop&game=BP_DiamondMine&token=37PoCZadVQxhqJtq6pr4CzugAGeB6AqfCQEpXHsODPzURiQKLb%3bdavid&language=ENG&playmode=real&affiliate=content
// lobby direct uat
// https://blueprintrgsuat.blueprintgaming.com/Mobile/BP_MOBAndyCapp/index.html?platform=desktop&game=&token=37PoCZadVQxhqJtq6pr4CzugAGeB6AqfCQEpXHsODPzURiQKLb;david&language=ENG&playmode=real&affiliate=direct
// lobby content uat
// https://blueprintrgsuat.blueprintgaming.com/loader/loader.aspx?platform=desktop&game=BP_AndyCapp&token=37PoCZadVQxhqJtq6pr4CzugAGeB6AqfCQEpXHsODPzURiQKLb;david&language=ENG&playmode=real&affiliate=content
// lobby direct dev2
// http://bpg-rgs-dev2.blueprintgaming.com/Mobile/MOB405_ZeusAlmighty/index.html?platform=desktop&game=&token=37PoCZadVQxhqJtq6pr4CzugAGeB6AqfCQEpXHsODPzURiQKLb;david&language=ENG&playmode=real&affiliate=direct
// lobby content dev2
// https://bpg-rgs-dev2.blueprintgaming.com/loader/loader.aspx?platform=desktop&game=&token=37PoCZadVQxhqJtq6pr4CzugAGeB6AqfCQEpXHsODPzURiQKLb;david&language=ENG&playmode=real&affiliate=content
// dev lobby direct dev (non-loader)
// http://bpg-rgs-dev2.blueprintgaming.com/Mobile/MOB208_BuffaloRising/index.html?platform=desktop&playmode=real&language=en&affiliate=direct&serverType=uat&customer=blueprint&assetsName=BP_BuffaloRisingJPK&token=Alex;Alex
// v3 dev game launch url
// https://contentrgsdev3.blueprintgaming.com/v3/start-game/develop/index.html?serverType=dev&token=fNOFQN7PARHD0TlLX6DuHe2FrRAdlIIPk8AHXwEO01I0xDMXSg;chris&customer=blueprint&dev&gameName=buffalorisingmegaways
