import React, {FunctionComponent, useState} from 'react';
import {
    Button, IconButton,
    Popover, styled, Typography,
} from '@mui/material';
import {AccountCircle} from '@mui/icons-material';
import {LoginAccount} from '../../models/account';
import {LobbyPermissions} from '../../constants/lobby-permissions';
import {useAppSelector} from "../../store/hooks/root-hooks";
import {selectCurrentUserHasPermissions} from "../../store/slices/accountSlice";
import {UserMenuEditForm} from "./UserMenuEditForm";
import {UserMenuViewForm} from "./UserMenuViewForm";
import {useLogoutMutation} from '../../store/apis/lobby-api';
import {theme} from "../../theme";
import {useBreakpoints} from '../../hooks/use-breakpoints';

const UserMenuButton = styled(Button)({
    color: theme.palette.text.primary,
    marginLeft: "auto",
})

export const UserMenu: FunctionComponent<{ currentUser: LoginAccount }> = ({currentUser}) => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [logout] = useLogoutMutation();
    const editAllowed = useAppSelector(selectCurrentUserHasPermissions(LobbyPermissions.SELF_ADMIN));

    const {isNarrow} = useBreakpoints();

    const handleAccountButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);
    const handleLogOutButtonClick = () => logout();
    const open = !!anchorEl;
    // const isBusy = isLoading || isUpdating;
    const userMenuButton = isNarrow ? (
        <IconButton onClick={handleAccountButtonClick}>
            <AccountCircle/>
        </IconButton>
    ) : (
        <UserMenuButton startIcon={<AccountCircle/>}
                        onClick={handleAccountButtonClick}
        >
            {currentUser.userid}
        </UserMenuButton>
    )
    return <>
        {userMenuButton}
        <Popover open={open}
                 anchorEl={anchorEl}
                 onClose={handleClose}
                 anchorOrigin={{vertical: "bottom", horizontal: "center"}}
                 transformOrigin={{vertical: "top", horizontal: "center"}}
        >
            <>
                {isNarrow && (
                    <Typography variant="h3" sx={{
                        width: "100%",
                        textAlign: "center",
                    }}>
                        {currentUser.userid}
                    </Typography>
                )}
                {editAllowed ? (
                    <UserMenuEditForm
                        currentUser={currentUser}
                        onLogOutClicked={handleLogOutButtonClick}
                    />
                ) : (
                    <UserMenuViewForm
                        currentUser={currentUser}
                        onLogOutClicked={handleLogOutButtonClick}
                    />
                )}
            </>
        </Popover>
    </>;
}
