import {createApi,} from "@reduxjs/toolkit/query/react";
import {AccountToUpdate, LoginAccount} from "../../models/account";
import {Permissions} from "../../models/permissions";
import {LabelledValue} from "../../models/labelled-value";
import {
    getDirectLaunchUrl,
    getLaunchGameName,
    getLoaderLaunchUrl,
    getReadableGameName,
    StoredGame
} from "../../models/game";
import {lobbyFetchBaseQuery} from "../../utils/lobbyBaseQuery";
import {FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta} from "@reduxjs/toolkit/query";
import {MaybePromise} from "@reduxjs/toolkit/dist/query/tsHelpers";
import {QueryReturnValue} from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import {prepareBaseUrlFromAPIRootConfig, prepareHeadersAcceptJSON} from "./shared-utils";
import {Server} from "../../models/server";
import {SlimLobbyTab, SlimLobbyTabSchema} from "../../models/lobby-tab";

type FetchWithBQType = (arg: (string | FetchArgs)) => MaybePromise<QueryReturnValue<unknown, FetchBaseQueryError, FetchBaseQueryMeta>>
const augmentUserWithPermissions = async (userAccount: LoginAccount, fetchWithBQ: FetchWithBQType) => {
    if (userAccount) {
        const permissionsResult = await fetchWithBQ({url: `login/perms`});
        if (permissionsResult.data){
            userAccount.permissions = permissionsResult.data as Permissions;
        }
    }
}

export const lobbyApi = createApi({
    reducerPath: "lobbyApi",
    baseQuery: lobbyFetchBaseQuery({
        prepareBaseUrl: prepareBaseUrlFromAPIRootConfig,
        prepareHeaders: prepareHeadersAcceptJSON,
        credentials: "include",
        mode: "cors",
    }),
    tagTypes: ["User", "Customers", "Servers", "Currencies", "Languages", "Affiliates", "Games", "TabDetails", "Wallet"],
    endpoints: (builder) => ({
        login: builder.mutation<LoginAccount, {userId: string, password: string}>({
            invalidatesTags: ["User", "Customers", "Servers", "Currencies", "Languages", "Affiliates", "Games"],
            // custom fn to do multiple requests as a single query
            queryFn: async (arg, queryApi, extraOptions, fetchWithBQ) => {
                const result = await fetchWithBQ({url: "login", method: "POST", body: arg});
                const accountData = result.data as LoginAccount | undefined;
                if (accountData) {
                    await augmentUserWithPermissions(accountData, fetchWithBQ);
                }
                return result.data
                    ? { data: result.data as LoginAccount}
                    : {error: result.error as FetchBaseQueryError}
            },
        }),
        logout: builder.mutation<unknown, void>({
            invalidatesTags: ["User", "Customers", "Servers", "Currencies", "Languages", "Affiliates", "Games"],
            queryFn: async(arg, queryApi, extraOptions, fetchWithBQ) => {
                const result = await fetchWithBQ({url: "login", method: "DELETE"});
                return result.data
                    ? { data: result.data}
                    : {error: result.error as FetchBaseQueryError}
            }
        }),
        checkLogin: builder.query<LoginAccount, void>({
            providesTags: ["User"],
            queryFn: async (arg, queryApi, extraOptions, fetchWithBQ) => {
                try {
                    const result = await fetchWithBQ({url: `login`, method: "GET"});
                    const accountData = result.data as LoginAccount | undefined;
                    if (accountData) {
                        await augmentUserWithPermissions(accountData, fetchWithBQ);
                    }
                    return result.data
                        ? { data: result.data as LoginAccount}
                        : { error: result.error as FetchBaseQueryError}
                }
                catch (e) {
                    return {error: e as FetchBaseQueryError}
                }
            }
        }),
        getServers: builder.query<Server[], void>({
            providesTags: ["Servers", "Customers", "Affiliates"],
            query: () => `lobby/servers`,
        }),
        getLanguages: builder.query<LabelledValue[], void>({
            providesTags: ["Languages"],
            query: () => `lobby/languages`,
        }),
        getCurrencies: builder.query<LabelledValue[], void>({
            providesTags: ["Currencies"],
            query: () => `lobby/currencies`,
        }),
        getPermissionsForLevel: builder.query<Permissions, number>({
            query: () => `login/perms`,
        }),
        getGames: builder.query<StoredGame[], {customer: string, server: string}>({
            providesTags: (result, error, {customer, server}) => [{type: "Games", id: `${customer}-${server}`}],
            query: ({customer, server}) => `lobby/games?server=${server}&customer=${customer}`,
            transformResponse: (games: StoredGame[]) => {
                // TODO: calculate isNew
                // 1970-01-01T00:00:00
                // calculate the displayName
                return games.map((game) => ({
                    ...game,
                    newUntil: game.newUntil,
                    displayName: game.readableName || getReadableGameName(game.name),
                    launchName: game.launchName || getLaunchGameName(game.gamepath),
                    directLaunchUrl: getDirectLaunchUrl(game.gamepath, game.customer, game.server),
                    loaderLaunchUrl: getLoaderLaunchUrl(game.customer, game.server),
                }));
            }
        }),
        getTabsDetails: builder.query<Record<SlimLobbyTab["tabId"], SlimLobbyTab>, Array<SlimLobbyTab["tabId"]>>({
            providesTags: (result) => Array.isArray(result) ? result.map(({tabId}) => ({type: "TabDetails", id: tabId})) : [],
            queryFn: async (tabIds: Array<SlimLobbyTab["tabId"]>, queryApi, extraOptions, fetchWithBQ) => {
                const results : Record<SlimLobbyTab["tabId"], SlimLobbyTab> = {};

                for(let tabIndex = 0; tabIndex < tabIds?.length ?? 0; tabIndex++) {
                    if(results[tabIds[tabIndex]] !== undefined) {
                        continue;
                    }

                    const currentTabResult = await fetchWithBQ(`lobby/tab/${tabIds[tabIndex]}`);

                    if(!currentTabResult.error) {
                        const parsedTabResult = SlimLobbyTabSchema.safeParse(currentTabResult.data);
                        if(parsedTabResult.success) {
                            results[tabIds[tabIndex]] = parsedTabResult.data;
                        }
                    }
                }

                return {data: results};
            }
        }),
        updateWalletSelf: builder.mutation<undefined, AccountToUpdate>({
            invalidatesTags: (result, error, {id}) => [{type: "Wallet", id}],
            // custom fn to get the current values and merge the partial over it, as the API doesn't support partial updates
            queryFn: async (editedAccount, api, extraOptions, fetchWithBQ) => {
                if (!editedAccount.id) {
                    return {
                        error: {
                            status: "CUSTOM_ERROR",
                            error: "updateAccount requires an account with an id value defined!"
                        }
                    }
                }

                const result = await fetchWithBQ({
                    url: `/lobby/wallet`,
                    method: "PATCH",
                    body: editedAccount.wallet
                });
                return result.error ? {error: result.error as FetchBaseQueryError} : {data: undefined}
            }
        })
    })
})

export const {
    useUpdateWalletSelfMutation,
    useGetTabsDetailsQuery,
    useLoginMutation,
    useLogoutMutation,
    useCheckLoginQuery,
    useLazyCheckLoginQuery,
    useGetCurrenciesQuery,
    useGetLanguagesQuery,
    useGetPermissionsForLevelQuery,
    useGetGamesQuery,
    useGetServersQuery,
    useLazyGetCurrenciesQuery,
    useLazyGetLanguagesQuery,
    useLazyGetPermissionsForLevelQuery,
    useLazyGetGamesQuery,
    useLazyGetServersQuery,
} = lobbyApi;
