import React, {forwardRef, useCallback, useMemo} from "react";
import ExpandedGame from "../models/expanded-game";
import {styled, Box} from '@mui/material';
import {StarOutlineRounded, StarRounded} from "@mui/icons-material";
import {selectFavouriteGames} from "../store/slices/lobbySlice";
import {useAppSelector} from "../store/hooks/root-hooks";
import {selectConfigFavouritesEnabled} from "../store/slices/configSlice";
import {GameLaunchLink} from "./GameLaunchLink";
import {ToggleIconButton} from "./common/inputs/ToggleIconButton";
import {GameImage} from './GameImage';

interface GameButtonProps {
    game: ExpandedGame;
    onFavouriteClick: (game: ExpandedGame) => void;
    overlays?: string[];
    width: number;
    height: number;
}

const GameButtonContainer = styled(Box)({
    position: "relative",
    width: "100%",
    height: "100%",
});
const GameButtonTitleBar = styled(Box)({
    backgroundColor: "rgba(0,0,0,0.5)",
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
});
const GameButtonTitleText = styled("span")(({theme}) => ({
    color: "#fff",
    overflow: "hidden",
    fontSize: "1rem",
    lineHeight: "24px",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
}));

const FavouritesButtonWrapper = styled("div")(({theme}) => ({}));


export const GameButton = forwardRef<HTMLDivElement, GameButtonProps>(({
                                                                           game,
                                                                           width,
                                                                           height,
                                                                           onFavouriteClick,
                                                                       }, ref) => {
    const allowFavourites = useAppSelector(selectConfigFavouritesEnabled);
    const favourites = useAppSelector(selectFavouriteGames);

    const isFavourite = useMemo(() => favourites.includes(game.id), [favourites, game]);
    const toggleIsFavourite = useCallback(() => {
        onFavouriteClick(game);
    }, [onFavouriteClick, game])

    return (
        <GameButtonContainer
            ref={ref}
            sx={{
                minWidth: width,
                minHeight: height,
            }}
        >
            <GameLaunchLink
                sx={{
                    position: "relative",
                    width: "100%",
                    height: "100%",
                }}
                game={game}
            >
                <GameImage game={game}/>
            </GameLaunchLink>
            <GameButtonTitleBar>
                <GameButtonTitleText>
                    {game.displayName}
                </GameButtonTitleText>
                {allowFavourites && (
                    <FavouritesButtonWrapper>
                        <ToggleIconButton
                            iconTrue={<StarRounded/>}
                            iconFalse={<StarOutlineRounded/>}
                            value={isFavourite}
                            onChange={toggleIsFavourite}
                        />
                    </FavouritesButtonWrapper>
                )}
            </GameButtonTitleBar>
        </GameButtonContainer>
    );
});

export default GameButton;
