import {BaseQueryFn, FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta} from "@reduxjs/toolkit/query";
import {FetchBaseQueryArgs, ResponseHandler} from "@reduxjs/toolkit/dist/query/fetchBaseQuery";
import {isPlainObject} from "@reduxjs/toolkit";
import {MaybePromise} from "@reduxjs/toolkit/dist/query/tsHelpers";
import {RootState} from "../store/store";
import {selectAPIRootUrl} from "../store/slices/configSlice";
import {joinUrls} from "./joinUrls";
import {BaseQueryApi} from "@reduxjs/toolkit/src/query/baseQueryTypes";
/**
 * This module is mostly a straight copy of fetchBaseQuery.ts from the rtk-query source, with modifications made to how
 * the URLs are handled
 */
/**
 * A mini-wrapper that passes arguments straight through to
 * {@link [fetch](https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API)}.
 * Avoids storing `fetch` in a closure, in order to permit mocking/monkey-patching.
 */
const defaultFetchFn: typeof fetch = (...args) => fetch(...args)

const defaultValidateStatus = (response: Response) =>
    response.status >= 200 && response.status <= 299

const isJsonContentType = (headers: Headers) =>
    headers.get('content-type')?.trim()?.startsWith('application/json')

const handleResponse = async (
    response: Response,
    responseHandler: ResponseHandler
) => {
    if (typeof responseHandler === 'function') {
        return responseHandler(response)
    }

    if (responseHandler === 'text') {
        return response.text()
    }

    if (responseHandler === 'json') {
        const text = await response.text()
        return text.length ? JSON.parse(text) : undefined
    }
}

function stripUndefined(obj: any) {
    if (!isPlainObject(obj)) {
        return obj
    }
    const copy: Record<string, any> = {...obj}
    for (const [k, v] of Object.entries(copy)) {
        if (typeof v === 'undefined') delete copy[k]
    }
    return copy
}

export type LobbyBaseQueryArgs = FetchBaseQueryArgs & {prepareBaseUrl?: (baseUrl: string | undefined, api: Pick<BaseQueryApi, 'getState' | 'endpoint' | 'type' | 'forced'>) => MaybePromise<string|undefined>}

export function lobbyFetchBaseQuery({
                                        baseUrl,
                                        prepareBaseUrl = (x) => x,
                                        prepareHeaders = (x) => x,
                                        fetchFn = defaultFetchFn,
                                        ...baseFetchOptions
                                    }: LobbyBaseQueryArgs = {}): BaseQueryFn<string | FetchArgs,
    unknown,
    FetchBaseQueryError,
    {},
    FetchBaseQueryMeta> {
    if (typeof fetch === 'undefined' && fetchFn === defaultFetchFn) {
        console.warn(
            'Warning: `fetch` is not available. Please supply a custom `fetchFn` property to use `fetchBaseQuery` on SSR environments.'
        )
    }
    return async (arg, api) => {
        let meta: FetchBaseQueryMeta | undefined
        let {
            url,
            method = 'GET' as const,
            headers = new Headers({}),
            body = undefined,
            params = undefined,
            responseHandler = 'json' as const,
            validateStatus = defaultValidateStatus,
            ...rest
        } = typeof arg == 'string' ? {url: arg} : arg
        let config: RequestInit = {
            ...baseFetchOptions,
            method,
            signal: api.signal,
            body,
            ...rest,
        }

        config.headers = await prepareHeaders(
            new Headers(stripUndefined(headers)),
            api
        )

        // Only set the content-type to json if appropriate. Will not be true for FormData, ArrayBuffer, Blob, etc.
        const isJsonifiable = (body: any) =>
            typeof body === 'object' &&
            (isPlainObject(body) ||
                Array.isArray(body) ||
                typeof body.toJSON === 'function')

        if (!config.headers.has('content-type') && isJsonifiable(body)) {
            config.headers.set('content-type', 'application/json')
        }

        if (body && isJsonContentType(config.headers)) {
            config.body = JSON.stringify(body)
        }

        if (params) {
            const divider = ~url.indexOf('?') ? '&' : '?'
            const query = new URLSearchParams(stripUndefined(params))
            url += divider + query
        }
        let fullBaseUrl = baseUrl;
        if (prepareBaseUrl) {
            fullBaseUrl = await prepareBaseUrl(baseUrl, api);
        }

        url = joinUrls(fullBaseUrl, url)

        const request = new Request(url, config)
        const requestClone = request.clone()
        meta = {request: requestClone}

        let response
        try {
            response = await fetchFn(request)
        } catch (e) {
            return {error: {status: 'FETCH_ERROR', error: String(e)}, meta}
        }
        const responseClone = response.clone()

        meta.response = responseClone

        let resultData
        try {
            resultData = await handleResponse(response, responseHandler)
        } catch (e) {
            return {
                error: {
                    status: 'PARSING_ERROR',
                    originalStatus: response.status,
                    data: await responseClone.clone().text(),
                    error: String(e),
                },
                meta,
            }
        }

        return validateStatus(response, resultData)
            ? {
                data: resultData,
                meta,
            }
            : {
                error: {
                    status: response.status,
                    data: resultData,
                },
                meta,
            }
    }
}

export const getLobbyBaseQuery = (admin = false) => lobbyFetchBaseQuery({
    prepareBaseUrl: (baseUrl, {getState}) => {
        const apiRootUrl = selectAPIRootUrl(getState() as RootState);
        return joinUrls(apiRootUrl, baseUrl);
    },
    prepareHeaders: ((headers, api) => {
        headers.set("accept", "application/json");
        if (admin) {
            headers.set("X-Account-ID", "")
        }
        return headers;
    })
})

