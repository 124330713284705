import {FunctionComponent} from "react";
import {Divider, ListSubheader} from "@mui/material";
import {NavDrawerItem, NavDrawerItemProps} from "./NavDrawerItem";

export interface NavDrawerItemGroupProps {
    /**
     * Optional title for the group
     */
    title?: string;
    /**
     * Items to show in the group
     *
     * As with {@link NavDrawer}, this supports `"-"` for dividers
     */
    items: Array<NavDrawerItemProps | "-">;

}

/**
 * Item group used in {@link NavDrawer}
 *
 * You probably won't need to use this directly
 */
export const NavDrawerItemGroup: FunctionComponent<NavDrawerItemGroupProps> = ({
                                                                                   title,
                                                                                   items
                                                                               }) => (
    <>
        <Divider/>
        {title && (
            <ListSubheader>
                {title}
            </ListSubheader>
        )}
        {items.map((item, index) => (
            item === "-"
                ? <Divider key={`${title}-${index}`}/>
                : (
                    <NavDrawerItem
                        key={`${title}-${index}`}
                        {...item}
                    />
                )
        ))}
    </>
)