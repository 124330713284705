import {
    WalletAccount,
    WalletAccountSchema,
    AdminAccount,
    AdminAccountSchema,
    LoginAccount,
    AccountToUpdate
} from "../../models/account";
import {z} from "zod";

export const EditableAccountSchema = AdminAccountSchema.pick({
    id: true
}).merge(WalletAccountSchema.pick({
    balanceCash: true,
    balanceFreebet: true,
    language: true,
    currency: true,
    country: true,
    realityCheckIntervalSecs: true,
})).merge(z.object({
    realityCheckIntervalSecs: z.number().nullable(),
}));

export type EditableAccount = z.infer<typeof EditableAccountSchema>; // z.infer uses the output after transforms for the type

export const convertAccountToEditableAccount = (account: LoginAccount | AdminAccount | WalletAccount): EditableAccount => {
    const {balanceCash, balanceFreebet, language, currency, country, realityCheckIntervalSecs} = (account as AdminAccount).wallet ?? account as WalletAccount;
    const id = account.id;
    return {id, balanceCash, balanceFreebet, language, currency, country, realityCheckIntervalSecs: realityCheckIntervalSecs ?? null};
}

export function convertEditableAccountToUpdateDto(account: EditableAccount) : AccountToUpdate {
    const {balanceCash, balanceFreebet, language, currency, country, realityCheckIntervalSecs} = account;

    return {
        id: account.id,
        wallet: {
            balanceFreebet,
            balanceCash,
            language,
            currency,
            country,
            realityCheckIntervalSecs
        }
    }
}