import {Link} from "react-router-dom";
import {FunctionComponent, HTMLAttributes, useCallback} from "react";
import Game from "../models/game";
import {useAppSelector} from "../store/hooks/root-hooks";
import {selectGameLaunchOptions} from "../store/slices/lobbySlice";
import {selectLaunchUrlForGameById} from "../store/misc-selectors";
import {Box} from "@mui/material";
import {SxProps, Theme} from "@mui/material/styles";


export const GameLaunchLink: FunctionComponent<HTMLAttributes<HTMLAnchorElement> & {game: Game, sx?: SxProps<Theme>}> = ({game, sx, ...props}) => {
    const {launchMode} = useAppSelector(selectGameLaunchOptions);
    const launchUrl = useAppSelector(selectLaunchUrlForGameById(game.id));
    const {id} = game;
    const renderLink = useCallback(() => {
        if (!launchUrl) {
            // eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid
            return <a href="#" {...props} />;
        }
        switch(launchMode) {
            case "new":
                // eslint-disable-next-line jsx-a11y/anchor-has-content,react/jsx-no-target-blank
                return <a href={launchUrl} target="_blank" {...props} />;
            case "iframe":
                return <Link to={`/game/${id}`} {...props} />;
            case "self":
            default:
                // eslint-disable-next-line jsx-a11y/anchor-has-content
                return <a href={launchUrl} {...props} />;
        }
    }, [launchMode, props, id, launchUrl]);

    return (
        <Box sx={sx}>{renderLink()}</Box>
    )
}

