import {MaybePromise} from "@reduxjs/toolkit/dist/query/tsHelpers";
import {joinUrls} from "../../utils/joinUrls";
import {LobbyConfig} from "../slices/configSlice";


export function prepareBaseUrlFromWithURLFromState<TState = unknown>(
    baseUrl: string | undefined,
    api: {getState: () => TState},
    selector: (state: TState) => string | undefined): MaybePromise<string | undefined> {

    const apiRootUrl = selector(api.getState())
    if (apiRootUrl === undefined) {
        throw new Error("Can't construct baseUrl as selector returned undefined!")
    }
    return joinUrls(apiRootUrl, baseUrl);
}

export const prepareBaseUrlFromAPIRootConfig = (baseUrl: string | undefined, api: {getState: () => unknown}) => {
    // cast to get around the fact we can't know about the shape of the RootState while creating an API because the API
    // is part of the shape of the RootState. Everyone loves circular dependencies!
    const apiRootSelector = ((state: {config: {config?: LobbyConfig}}) => state.config.config?.apiConfig.root) as (state: unknown) => string | undefined;
    return prepareBaseUrlFromWithURLFromState(baseUrl, api, apiRootSelector)
}

export function prepareHeadersAcceptJSON(headers: Headers): Headers {
    headers.set("accept", "application.json");
    return headers;
}