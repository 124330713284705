import { z } from 'zod';
import {AdminGameSchema} from './game';

export const LobbyTabSchema = z.object({
    tabId: z.number(),
    tabName: z.string(),
    games: z.array(AdminGameSchema),
    sequence: z.number().nullish(),
    systemTabType: z.string().nullish(),
    theme: z.object({
        themeName: z.string(),
        style: z.string(),
    }).nullish(),
})
export type LobbyTab = z.infer<typeof LobbyTabSchema>;

export const CreateLobbyTabSchema = LobbyTabSchema.pick({tabName: true});
export type CreateLobbyTab = z.infer<typeof CreateLobbyTabSchema>;
export const EditLobbyTabSchema = LobbyTabSchema.omit({games: true});
export type EditLobbyTab = z.infer<typeof EditLobbyTabSchema>;

export const SlimLobbyTabSchema = LobbyTabSchema.omit({games: true});
export type SlimLobbyTab = z.infer<typeof SlimLobbyTabSchema>;
