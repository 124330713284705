import {
    AppBar,
    Box,
    Breadcrumbs,
    Container,
    IconButton,
    LinearProgress,
    styled,
    Toolbar,
    Typography,
} from "@mui/material";
import {Close, Menu as MenuIcon} from "@mui/icons-material";
import {UserMenu} from "./components/user-menu/UserMenu";
import React, {
    createContext,
    FunctionComponent,
    Suspense,
    useContext,
    useEffect,
    useMemo,
    useState
} from "react";
import {LobbyAdminDrawer} from "./components/LobbyAdminDrawer";
import {useAppSelector} from "./store/hooks/root-hooks";
import {selectConfigLobbyTitle, selectLobbyFeaturesEnabled} from "./store/slices/configSlice";
import {selectShowAdmin} from "./store/misc-selectors";
import {Outlet} from "react-router-dom";
import {selectShowAppBarLobbyOptions} from './store/slices/lobbySlice';
import {LobbyAppBarOptions} from './components/lobby-options/LobbyAppBarOptions';
import {useBreakpoints} from './hooks/use-breakpoints';
import {selectCurrentUser} from './store/slices/accountSlice';

export interface PageLayoutProps {
    scrollable?: boolean;
}

const AppContainer = styled(Box)({
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    overflow: "hidden",
    maxHeight: "100vh",
});

export const ContentContainer = styled(Container)({
    flexGrow: 1,
    position: "relative",
    bottom: 0,
    display: "flex",
    flexDirection: "column",
});

export const ScrollableContainer = styled(ContentContainer)({
    overflow: "auto",
});


const AppBarContentContext = createContext({
    setPagePath: (path: string) => console.log(`setPageTitle(${path.split("/").map((p) => p.trim()).join(" > ")})`),
    setShowAppBarLoadingIndicator: (loading: boolean) => console.log(`setShowAppBarLoadingIndicator(${loading})`),
});

export const usePageAppBar = (pagePath: string) => {
    const {setPagePath} = useContext(AppBarContentContext);
    useEffect(() => {
        setPagePath(pagePath);
        return () => {
            setPagePath("");
        }
    }, [setPagePath, pagePath]);


    return {setPagePath};
}
export const useGlobalLoadingIndicator = () => {
    const {setShowAppBarLoadingIndicator} = useContext(AppBarContentContext);
    return setShowAppBarLoadingIndicator;
}

export const GlobalLoadIndicator = () => {
    const showGlobalLoadIndicator = useGlobalLoadingIndicator();
    useEffect(() => {
        showGlobalLoadIndicator(true);
        return () => {
            showGlobalLoadIndicator(false);
        }
    }, [showGlobalLoadIndicator]);
    return null;
}

export const LobbySuspense: FunctionComponent = ({children}) => (
    <Suspense fallback={<GlobalLoadIndicator/>}>
        {children}
    </Suspense>
)


export const PageLayout: FunctionComponent<PageLayoutProps> = ({
                                                                   scrollable,
                                                                   children
                                                               }) => {
    const lobbyTitle = useAppSelector(selectConfigLobbyTitle);
    const {userMenu} = useAppSelector(selectLobbyFeaturesEnabled);
    const showAdmin = useAppSelector(selectShowAdmin);
    const user = useAppSelector(selectCurrentUser);
    const showAppBarLobbyOptions = useAppSelector(selectShowAppBarLobbyOptions);

    const {isNarrow} = useBreakpoints();


    const [menuOpen, setMenuOpen] = useState(false);
    const onMenuButtonClicked = () => setMenuOpen(!menuOpen);

    const [pagePath, setPagePath] = useState("");
    const [loading, setLoading] = useState(false);
    const fullPath = useMemo(() =>
            [
                lobbyTitle,
                ...pagePath.split("/")
            ]
                .filter((p) => !!p)
                .map((p) => p.trim()),
        [lobbyTitle, pagePath]);

    const showAppBarTitle = !isNarrow || !showAppBarLobbyOptions || fullPath.length > 1;

    const ContentContainerComponent = scrollable ? ScrollableContainer : ContentContainer;

    return (
        <AppContainer>
            <AppBar
                position="sticky"
                sx={{
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
            >
                <Toolbar>
                    {showAdmin && (
                        <IconButton
                            onClick={onMenuButtonClicked}
                            size="large"
                        >
                            {menuOpen ? <Close/> : <MenuIcon/>}
                        </IconButton>)
                    }
                    {showAppBarTitle && (
                        <Breadcrumbs
                            aria-label={"breadcrumb"}
                            sx={{
                                flexGrow: 1,
                            }}
                        >
                            {fullPath.map((part, index, arr) => (
                                <Typography
                                    key={index}
                                    variant="h6"
                                    color={index === arr.length - 1 ? "text.primary" : undefined}
                                >
                                    {part}
                                </Typography>
                            ))}
                        </Breadcrumbs>
                    )}
                    {showAppBarLobbyOptions && (
                        <LobbyAppBarOptions/>
                    )}
                    {user && userMenu && (
                        <UserMenu currentUser={user}/>
                    )}
                </Toolbar>
            </AppBar>
            {showAdmin && (
                <LobbyAdminDrawer
                    open={menuOpen}
                    onClose={() => setMenuOpen(false)}
                />
            )}
            {loading && (
                <LinearProgress/>
            )}
            <AppBarContentContext.Provider value={{
                setPagePath,
                setShowAppBarLoadingIndicator: setLoading,
            }}>
                <ContentContainerComponent>
                    {children}
                </ContentContainerComponent>
            </AppBarContentContext.Provider>
        </AppContainer>
    )
}

export const RouterPageLayout: FunctionComponent<PageLayoutProps> = (props) => (
    <PageLayout {...props}>
        <Outlet/>
    </PageLayout>
)
