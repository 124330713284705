import {FunctionComponent, useMemo} from "react";
import {useGetServersQuery} from "../../../store/apis/lobby-api";
import * as React from "react";
import {AsyncStringSelect, AsyncStringSelectProps} from "../../common/inputs/AsyncStringSelect";


export interface ServerSelectProps extends Omit<AsyncStringSelectProps<true>, "loading" | "options"> {
    label: string;
    value?: string;
    onValueChange: (newValue: string) => void;
}

export function useServerSelect(): {isServerListLoading: boolean, servers: string[]} {
    const {data, isLoading: isServerListLoading} = useGetServersQuery();
    const servers = useMemo(() => data?.slice().sort() ?? [], [data]);
    return {isServerListLoading, servers: servers.map(({name}) => name)};
}

export const ServerSelect: FunctionComponent<ServerSelectProps> = ({
                                                                       label,
                                                                       value,
                                                                       onValueChange,
                                                                       ...props
                                                                   }) => {
    const {servers, isServerListLoading} = useServerSelect();
    return (
        <AsyncStringSelect
            label={label}
            options={servers}
            loading={isServerListLoading}
            value={value}
            onValueChange={onValueChange}
            disableClearable
            {...props}
            sx={{
                minWidth: "10em",
                ...props.sx,
            }}
        />
    )
}
