import {LoginAccount} from "../../models/account";
import {FunctionComponent, useEffect, useState} from "react";
import {
    convertAccountToEditableAccount,
    EditableAccount,
} from "./user-menu-types";
import {UserMenuForm} from "./UserMenuForm";
import {useLazyCheckLoginQuery} from "../../store/apis/lobby-api";
import {useLazyGetAccountByIdQuery} from "../../store/apis/admin-api";

export interface UserMenuViewFormProps {
    currentUser: LoginAccount;
    onLogOutClicked: () => void;
}

export const UserMenuViewForm: FunctionComponent<UserMenuViewFormProps> = ({
                                                                               currentUser,
                                                                               onLogOutClicked,
                                                                           }) => {
    const [refreshAccount, {data: serverAccount, isLoading}] = useLazyCheckLoginQuery();
    const [refreshAdminAccount, {isLoading: isRefreshingAdmin}] = useLazyGetAccountByIdQuery();
    const [account, setAccount] = useState<EditableAccount>(convertAccountToEditableAccount(currentUser));

    useEffect(() => {
        serverAccount && setAccount(convertAccountToEditableAccount(serverAccount));
    }, [serverAccount]);

    const onRefreshDataClicked = () => {
        refreshAccount();
        refreshAdminAccount("me");
    }

    return (
        <UserMenuForm
            onRefreshDataClicked={onRefreshDataClicked}
            onLogOutClicked={onLogOutClicked}
            account={account}
            isBusy={isLoading || isRefreshingAdmin}
            isEditable={false}
        />
    )
}
