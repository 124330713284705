export enum LobbyPermissions {
    LIMITED_GAMES,
    SELF_ADMIN,
    ALL_GAMES,
    ADMIN_ALL_USERS_LIMITED,
    ADMIN_ALL_USERS_FULL,
    ADMIN_GAMES,
    ADMIN_EXCLUSIONS
}

export type AdminPageName = "games" | "users" | "exclusions";