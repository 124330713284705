import {FunctionComponent, useEffect, useState} from "react";
import {LoginAccount} from "../../models/account";
import {useGetAccountByIdQuery, useLazyGetAccountByIdQuery} from "../../store/apis/admin-api";
import {useUpdateWalletSelfMutation} from "../../store/apis/lobby-api";
import {
    convertAccountToEditableAccount, convertEditableAccountToUpdateDto,
    EditableAccount,
} from "./user-menu-types";
import {UserMenuForm} from "./UserMenuForm";

export interface UserMenuEditFormProps {
    currentUser: LoginAccount;
    onLogOutClicked: () => void;
}

export const UserMenuEditForm: FunctionComponent<UserMenuEditFormProps> = ({
                                                                               currentUser,
                                                                               onLogOutClicked,
                                                                           }) => {
    const {data: serverAccount, refetch: refetchAccount, isLoading} = useGetAccountByIdQuery("me");
    const [updateWallet, {isLoading: isUpdating}] = useUpdateWalletSelfMutation();
    const [refreshAdminAccount, {isLoading: isRefreshingAdmin}] = useLazyGetAccountByIdQuery();
    // start by converting the LoginAccount to an EditableAccount
    const [account, setAccount] = useState<EditableAccount>(convertAccountToEditableAccount(currentUser));

    useEffect(() => {
        // once we've got the server account, update the EditableAccount to be based on that too, since it's newer
        // (and will allow for some things to be easier)
        if (serverAccount) {
            setAccount(convertAccountToEditableAccount(serverAccount));
        }
    }, [serverAccount]);

    const onSubmit = (values: EditableAccount) => {
        // merge the editable subset into the existing account
        if (account) {
            // call the api
            updateWallet(convertEditableAccountToUpdateDto({
                ...account,
                ...values,
            }));
            // The flow of this seems to be a bit messy and connected, so there's no great way of keeping track of what's happening where.
            // This is a fix for https://git.blueprintgaming.com/framework-team/lobby-frontend/-/issues/98
            // It keeps the submit button disabled when there hasn't been a change, but without adding either a lot of random code everywhere or doing substantial refactors, I couldn't really see a way of sorting this
            // Might be me being blind or stupid, but this felt like the easy fix that shouldn't really hurt anything. Might only cause someone to lose something if they change it <1s after saving but meh

            //To clarify, this isn't to refresh the data, it's to refresh the checks for input modification (like changing the balance without closing the panel)
            setTimeout(()=>{
                onRefreshDataClicked();
            }, 1000)
        }
    }

    const onRefreshDataClicked = () => {
        refetchAccount();
        refreshAdminAccount("me");
    }

    return (
        <UserMenuForm
            onRefreshDataClicked={onRefreshDataClicked}
            onLogOutClicked={onLogOutClicked}
            account={account}
            isBusy={isLoading || isUpdating || isRefreshingAdmin}
            isEditable={true}
            onSubmit={onSubmit}
        />
    )
}
