import React/*, {forwardRef, useMemo}*/ from 'react';
import {ListItem, ListItemIcon, ListItemText} from '@mui/material';
import {/*Link, LinkProps,*/ useNavigate} from 'react-router-dom';
import styled from "@emotion/styled";

interface ListItemLinkProps {
    /**
     * Optional icon to show for the {@link ListItemLink}
     */
    icon?: React.ReactElement;
    /**
     * Text for the {@link ListItemLink}
     */
    primary: string;
    /**
     * Path for the {@link ListItemLink}
     */
    to: string;

    onClick? : () => void;
}

const CustomListItem = styled(ListItem)`
    cursor: pointer;
`

/**
 * React-Router {@link Link} wrapped in a MUI {@link ListItem}
 */
export function ListItemLink(props: ListItemLinkProps) {
    const { icon, primary, to, onClick } = props;
    const nav = useNavigate();

    /*const renderLink = useMemo(
        () =>
            forwardRef<any, Omit<LinkProps, 'to'>>((itemProps, ref) => (
                <Link to={to} ref={ref} {...itemProps} />
            )),
        [to],
    );*/

    const onClickLink = () => {
        if(onClick){
            onClick();
        }

        nav(to);
    }

    return (
        <li>
            <CustomListItem onClick={onClickLink}>
                {icon ? <ListItemIcon onClick={onClick}>{icon}</ListItemIcon> : null}
                <ListItemText primary={primary} />
            </CustomListItem>
        </li>
    );
}
