import {FunctionComponent} from "react";
import {Divider, Drawer, List, Toolbar} from "@mui/material";
import {NavDrawerItemGroup, NavDrawerItemGroupProps} from "./NavDrawerItemGroup";
import {isNavDrawerItemProps, NavDrawerItem, NavDrawerItemProps} from "./NavDrawerItem";

export interface NavDrawerProps {
    /**
     * Is the NavDrawer currently open?
     */
    open: boolean;
    /**
     * Callback for when the NavDrawer is closed
     *
     * This should set open to false in the parent component
     */
    onClose: () => void;
    /**
     * Items to display in the NavDrawer
     *
     * "-" is used to denote a divider. Also supports NavDrawerItemGroups for when you want grouped sections
     */
    items: Array<NavDrawerItemProps | NavDrawerItemGroupProps | "-">;
    /**
     * Should the NavDrawer leave space for a Toolbar (such as when the NavDrawer is being used with an AppBar)?
     */
    includeAppBarPadding?: boolean;
}

/**
 * Drawer menu that provides links to pages.
 */
export const NavDrawer: FunctionComponent<NavDrawerProps> = ({
                                                                 open,
                                                                 onClose,
                                                                 includeAppBarPadding,
                                                                 items,
                                                             }) => {
    return (
        <Drawer
            open={open}
            onClose={onClose}
            onClick={onClose}
        >
            {includeAppBarPadding && (
                <Toolbar/>
            )}
            <List component="nav">
                {items.map((item, index) =>
                    item === "-"
                        ? <Divider key={index}/>
                        : isNavDrawerItemProps(item)
                            ? (
                                <NavDrawerItem
                                    key={index}
                                    {...item}
                                />
                            )
                            : (
                                <NavDrawerItemGroup
                                    key={index}
                                    {...item}
                                />
                            )
                )}
            </List>
        </Drawer>
    )
}