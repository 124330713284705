import React, {FunctionComponent} from 'react';
import {HashRouter} from 'react-router-dom';
import {ThemeProvider} from "@mui/material/styles";
import {theme} from './theme';
import {CssBaseline} from "@mui/material";
import {LocalizationProvider} from '@mui/lab';
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {LobbyRoutes} from './routes/LobbyRoutes';
import {LobbyConfig} from './store/slices/configSlice';
import {getBuildVersion} from "./version";

export const App: FunctionComponent<{config: LobbyConfig}> = () => {
    const baseUrl = document.querySelector('base')?.getAttribute('href') ?? undefined;
    const logStyle = [
        "color:rgb(73,148,207); background:black; font-weight: bold; font-family: sans-serif",
        "color: white; background:black;",
    ];
    console.log("%c%s %c%s ", logStyle[0], "lobby-frontend-version: ", logStyle[1], getBuildVersion());

    // TODO: build some parts of theme based on the config (for example, background of the AppBar)
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline enableColorScheme/>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <HashRouter basename={baseUrl}>
                    <LobbyRoutes/>
                </HashRouter>
            </LocalizationProvider>
        </ThemeProvider>
    );
}
