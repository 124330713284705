import {useAppSelector} from "../../store/hooks/root-hooks";
import {
    selectCurrentUserHasAdminPageAccess,
    selectCurrentUserHasPermissions
} from "../../store/slices/accountSlice";
import {FunctionComponent, ReactElement} from "react";
import {Navigate, useLocation} from "react-router-dom";
import {AdminPageName, LobbyPermissions} from "../../constants/lobby-permissions";
import {useAuth} from '../../hooks/use-auth';
import {useGlobalLoadingIndicator} from "../../PageLayout";
import React, {useEffect} from 'react';

interface RequireHelperProps {
    redirectTo: string;
    children: ReactElement;
    noHistoryOnRedirect?: boolean;
}

const AccessGuard: FunctionComponent<{ allowed: boolean } & RequireHelperProps> = ({
                                                                                       children,
                                                                                       redirectTo,
                                                                                       allowed,
                                                                                       noHistoryOnRedirect,
                                                                                   }) => {
    const location = useLocation();
    return allowed
        ? children
        : (
            <Navigate
                to={redirectTo}
                replace={noHistoryOnRedirect}
                state={{from: location}}
            />
        );
}

export const RequireAuth: FunctionComponent<RequireHelperProps> = (props) => {
    const {user, isInitialised, sessionExpired} = useAuth();
    const showGlobalLoadIndicator = useGlobalLoadingIndicator();
    useEffect(() => {
        if (!isInitialised && !user && !sessionExpired) {
            showGlobalLoadIndicator(true);
            return
        }
        showGlobalLoadIndicator(false);
    }, [isInitialised, sessionExpired, showGlobalLoadIndicator, user]);
    // noinspection RequiredAttributes
    return isInitialised ? (
            <AccessGuard
                allowed={!!user && !sessionExpired}
                {...props}
            />
        ) : (<></>)
}

export const RequirePermission: FunctionComponent<{ permission: LobbyPermissions } & RequireHelperProps> = ({
                                                                                                                permission,
                                                                                                                ...props
                                                                                                            }) => {
    const {isInitialised} = useAuth();
    const userAllowed = useAppSelector(selectCurrentUserHasPermissions(permission));
    // noinspection RequiredAttributes
    return isInitialised ? (
        <AccessGuard
            allowed={userAllowed}
            {...props}
        />
    ) : (<></>)
}

export const RequireAdminAccess: FunctionComponent<{ page: AdminPageName } & RequireHelperProps> = ({
                                                                                                        page,
                                                                                                        ...props
                                                                                                    }) => {
    const {isInitialised} = useAuth();
    const userAllowed = useAppSelector(selectCurrentUserHasAdminPageAccess(page));
    // noinspection RequiredAttributes
    return isInitialised ? (
        <AccessGuard
            allowed={userAllowed}
            {...props}
        />
    ) : (<></>)
}

