import {createApi} from "@reduxjs/toolkit/query/react";
import type {AdminGame, StoredAdminGame} from "../../models/game";
import Game, {storedAdminGameToAdminGame} from '../../models/game';
import type {AccountToCreate, AdminAccount, AdminAccountListEntry} from "../../models/account";
import type {Exclusion} from "../../models/exclusion";
import {lobbyFetchBaseQuery} from "../../utils/lobbyBaseQuery";
import {ListResult} from '../../models/list-result';
import {AddGameSetDTO, EditGameSetDTO, GameSet} from "../../models/game-set";
import {prepareBaseUrlFromAPIRootConfig, prepareHeadersAcceptJSON} from "./shared-utils";
import {CreateLobbyTab, EditLobbyTab, LobbyTab} from '../../models/lobby-tab';
import {useMemo} from 'react';
import {CollectionUpdate} from "../../models/collection-update";
import {LobbyRole} from "../../models/lobby-role";

type SearchQueryArg = { search: string };

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function isSearchQueryArg(arg: unknown): arg is SearchQueryArg {
    return !!(arg as SearchQueryArg)?.search;
}

type PaginatedQueryArg = { pageIndex: number, pageSize: number };

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function isPaginatedQueryArg(arg: unknown): arg is PaginatedQueryArg {
    return typeof (arg as PaginatedQueryArg)?.pageIndex === "number" && typeof (arg as PaginatedQueryArg)?.pageSize === "number";
}

type AdminGetQueryArg<TExtraArgs extends object = {}> =
    (Partial<SearchQueryArg> & Partial<PaginatedQueryArg> & TExtraArgs);

type HydratedAdminGetQueryArg<TExtraArgs extends object = {}> = AdminGetQueryArg<TExtraArgs & {include?: string[]}>;

function paramsToQueryString(params?: Record<string, string>) {
    if (!params) {
        return "";
    }
    return `?${new URLSearchParams(params)}`;
}

const getSearchStringFromQueryArgs = (args: AdminGetQueryArg | HydratedAdminGetQueryArg | void) => {
    const params = new URLSearchParams();
    if (args) {
        Object.entries(args as Record<string, string>).forEach(([key, value]) => {
            if(key === "include") {
                return;
            }

            if (value) {
                params.set(key, value);
            }
        });

        const hydrateProps = (args as HydratedAdminGetQueryArg)?.include;
        if(Array.isArray(hydrateProps) && typeof hydrateProps[0] === "string") {
            params.set("include", hydrateProps.join(","));
        }
    }
    // if (isSearchQueryArg(args)) {
    //     params.set("search", args.search);
    // }
    // if (isPaginatedQueryArg(args)){
    //     params.set("pageIndex", `${args.pageIndex}`);
    //     params.set("pageSize", `${args.pageSize}`);
    // }
    const search = params.toString();
    return search ? `?${search}` : "";
}

export const adminApi = createApi({
    reducerPath: "adminApi",
    baseQuery: lobbyFetchBaseQuery({
        baseUrl: "admin",
        prepareBaseUrl: prepareBaseUrlFromAPIRootConfig,
        prepareHeaders: prepareHeadersAcceptJSON,
        credentials: "include",
        mode: "cors",
    }),
    tagTypes: ["Account", "Game", "Exclusion", "GameSet", "GameSetDetails", "LobbyTab", "AccountGames", "AccountGameSets", "Customers", "Roles", "AccountRole"],
    endpoints: (builder) => ({
        getGames: builder.query<ListResult<StoredAdminGame>, AdminGetQueryArg<{ customer?: string, server?: string }> | void>({
            providesTags: (result) => {
                const type = "Game";
                const resultIds = result?.data.map(({id}) => ({type, id} as const));
                if (resultIds) {
                    return [...resultIds, {type, id: "LIST"}]
                }
                return [{type, id: "LIST"}];
            },
            query: (args) => `/games/${getSearchStringFromQueryArgs(args)}`,
        }),
        getGameById: builder.query<StoredAdminGame, number>({
            providesTags: (result, error, id) => [{type: "Game", id}],
            query: (id: number) => `/games/${id}`,
        }),
        getGamesServers: builder.query<string[], void>({
            query: () => "/games/servers",
        }),
        getGamesCustomers: builder.query<string[], {server?: string} | undefined>({
            query: (params) => `/games/customers${paramsToQueryString(params)}`,
        }),
        getGamesFormats: builder.query<string[], {server?: string, customer?: string} | undefined>({
            query: (params) => `/games/formats${paramsToQueryString(params)}`
        }),
        createGame: builder.mutation<StoredAdminGame, Omit<AdminGame, "id">>({
            invalidatesTags: (result) => [{type: "Game", id: result?.id ?? "ERROR"}, {type: "Game", id: "LIST"}],
            query: (newGame) => ({
                url: `/games/`,
                method: "POST",
                body: newGame,
            })
        }),
        updateGame: builder.mutation<StoredAdminGame, AdminGame>({
            invalidatesTags: (result, error, {id}) => [{type: "Game", id}, {type: "Game", id: "LIST"}],
            query: (editedGame) => ({
                url: `games/${editedGame.id}`,
                method: "PUT",
                body: editedGame,
            })
        }),
        removeGame: builder.mutation<void, AdminGame>({
            invalidatesTags: (result, error, {id}) => [{type: "Game", id}, {type: "Game", id: "LIST"}],
            query: (gameToRemove) => ({
                url: `/games/${gameToRemove.id}`,
                method: "DELETE",
            })
        }),
        updateGamesInAccount: builder.mutation<void, {userId: AdminAccount["id"], gameIds: CollectionUpdate<AdminGame["id"]>}>({
            invalidatesTags: (result, error, {userId}) => [{type: "AccountGames", id: userId}],
            query: ({userId, gameIds}) => ({
                url: `/users/${userId}/games`,
                method: "POST",
                body: {...gameIds}
            })
        }),
        addGameSetToAccount: builder.mutation<void, {userId: AdminAccount["id"]} & Pick<GameSet, "gameSetId">>({
            invalidatesTags: (result, error, {userId, gameSetId}) => [
                {type: "AccountGameSets", id: userId},
                ...["GameSet", "GameSetDetails"].map((type) => ({type: type as "GameSet" | "GameSetDetails", id: gameSetId}))
            ],
            query: ({userId, gameSetId}) => ({
                url: `/users/${userId}/gamesets/${gameSetId}`,
                method: "PUT"
            })
        }),
        removeGameSetFromAccount: builder.mutation<void, {userId: AdminAccount["id"]} & Pick<GameSet, "gameSetId">>({
            invalidatesTags: (result, error, {userId, gameSetId}) => [
                {type: "AccountGameSets", id: userId},
                ...["GameSet", "GameSetDetails"].map((type) => ({type: type as "GameSet" | "GameSetDetails", id: gameSetId}))
            ],
            query: ({userId, gameSetId}) => ({
                url: `/users/${userId}/gamesets/${gameSetId}`,
                method: "DELETE"
            })
        }),
        getCustomers: builder.query<string[], void>({
            providesTags: ["Customers"],
            query: () => "games/customers"
        }),
        getAccounts: builder.query<ListResult<AdminAccountListEntry>, HydratedAdminGetQueryArg | void>({
            providesTags: (result) => {
                const type = "Account";
                const resultIds = result?.data.map(({id}) => ({type, id} as const));
                if (resultIds) {
                    return [...resultIds, {type, id: "LIST"}]
                }
                return [{type, id: "LIST"}];
            },
            query: (args) => ({
                url: `/users${getSearchStringFromQueryArgs(args)}`,
                method: "GET"
            })
        }),
        getAccountById: builder.query<AdminAccount, number | "me">({
            providesTags: (result, error, id) => [{type: "Account", id}],
            query: (id) => `/users/${id}`,
        }),
        getAllRoles: builder.query<LobbyRole[], void>({
            providesTags: () => [{type: "Roles", id: "LIST"}],
            query: () => "/roles/"
        }),
        getAccountRole: builder.query<LobbyRole["name"], AdminAccount["id"]>({
            providesTags: (result, error, arg) => [{type: "AccountRole", id: arg}],
            query: (arg) => ({
                url: `/users/${arg}/role`,
                responseHandler: "text"
            }),
        }),
        createAccount: builder.mutation<AdminAccount | undefined, {account: AccountToCreate, role: string}>({
            invalidatesTags: (result) => [{type: "Account", id: result?.id ?? "ERROR"},
                {type: "Account", id: "LIST"},
                ...(typeof result?.id === "number" ? [{type: "AccountRole" as const, id: result.id }] : []),
                ...(typeof result?.id === "number" ? [{type: "AccountGameSets" as const, id: result.id}] : [])
            ],
            queryFn: async (arg, api, extraOptions, baseQuery) => {
                const accCreateResult = await baseQuery({url: "/users/", method: "POST", body: {...arg.account, id: -1, newPassword: arg.account.password}});

                // Immediately after creating the account object, assign it the requested role.
                if(accCreateResult.data && !accCreateResult.error) {
                    await baseQuery({url: `/users/${(accCreateResult.data as AdminAccount).id}/role/${arg.role}`, method: "PUT"})
                }

                return {data: accCreateResult.error ? undefined : accCreateResult.data as AdminAccount};
            }
        }),
        updateAccount: builder.mutation<AdminAccount | undefined, {account: AdminAccount, role: string}>({
            invalidatesTags: (result, error, {account}) => [{type: "Account", id: account.id}, {type: "Account", id: "LIST"}, {type: "AccountGameSets", id: account.id}, {type: "AccountRole", id: account.id}],
            queryFn: async (arg, api, extraOptions, baseQuery) => {
                const accUpdateResult = await baseQuery({url: `/users/${arg.account.id}`, method: "PUT", body: {
                    ...arg.account,
                    newPassword: arg.account.password,
                    oldPassword: null,
                    password: undefined
                }});
                const roleUpdateResult = await baseQuery({url: `/users/${arg.account.id}/role/${arg.role}`, method: "PUT"});

                const hasError = accUpdateResult.error || roleUpdateResult.error;

                return {data: hasError ? undefined : accUpdateResult.data as AdminAccount};
            }
        }),
        removeAccount: builder.mutation<void, AdminAccount>({
            invalidatesTags: (result, error, {id}) => [{type: "Account", id}, {type: "Account", id: "LIST"}, {type: "AccountGameSets", id}],
            query: (accountToRemove) => ({
                url: `/users/${accountToRemove.id}`,
                method: "DELETE",
            })
        }),
        getExclusions: builder.query<ListResult<Exclusion>, AdminGetQueryArg<{ server: string, customer: string }>>({
            providesTags: (result, error, args: AdminGetQueryArg<{server: string, customer: string}>) => [{
                type: "Exclusion",
                id: `LIST:${args.server}:${args.customer}`
            }],
            query: (args) => `/selfExclude/${args.server}/${args.customer}/${getSearchStringFromQueryArgs(args)}`,
        }),
        getExclusionByUserId: builder.query<Exclusion, { server: string, customer: string, userId: string }>({
            providesTags: (result, error, {server, customer, userId}) => [{
                type: "Exclusion",
                id: `${server}:${customer}:${userId}`
            }],
            query: ({server, customer, userId}) => `/selfExclude/${server}/${customer}/${userId}`,
        }),
        removeExclusion: builder.mutation<void, { server: string, customer: string, userId: string }>({
            invalidatesTags: (result, error, {server, customer, userId}) => [{
                type: "Exclusion",
                id: `${server}:${customer}:${userId}`
            }],
            query: ({server, customer, userId}) => ({
                url: `/selfExclude/${server}/${customer}/${userId}`,
                method: "DELETE",
            })
        }),
        getGamesForAccount: builder.query<Game[], Pick<AdminAccount, "id">>({
            providesTags: (result, errors, {id}) => [{type: "AccountGames", id}],
            query: ({id}) => ({
                url: `/users/${id}/games`,
                method: "GET"
            })
        }),
        getGameSetsForAccount: builder.query<GameSet[], Pick<AdminAccount, "id"> & Pick<HydratedAdminGetQueryArg, "include">>({
            providesTags: (r, e, args: Pick<AdminAccount, "id"> & Pick<HydratedAdminGetQueryArg, "include">) => [
                {type: "AccountGameSets", id: args.id},
                ...(r?.map?.((gameSet : GameSet) => ({
                    type: "GameSetDetails" as const,
                    id: gameSet.gameSetId
                })) ?? [])
            ],
            query: (args) => `users/${args.id}/gamesets${getSearchStringFromQueryArgs(args.include ? {include: args.include} : undefined)}`
        }),
        getGameSets: builder.query<ListResult<GameSet>, HydratedAdminGetQueryArg | void>({
            providesTags: (result) => {
                const type = "GameSet";
                const resultIds = result?.data.map(({gameSetId}) => ({type, id: gameSetId} as const));
                if (resultIds) {
                    return [...resultIds, {type, id: "LIST"}]
                }
                return [{type, id: "LIST"}];
            },
            query: (args?: HydratedAdminGetQueryArg) => `/gameSets/${getSearchStringFromQueryArgs(args)}`,
            transformResponse: (response: ListResult<GameSet>) => {
                // api can return null for games/accounts, we want an empty array in these cases, so transform the
                // response to do that
                response.data.forEach((gameSet) => {
                    if (gameSet.games === null) {
                        gameSet.games = [];
                    }
                    if (gameSet.accounts === null) {
                        gameSet.accounts = [];
                    }
                });
                return response;
            }
        }),
        getGameSetById: builder.query<GameSet, GameSet["gameSetId"]>({
            providesTags: (result, error, id) => [{type: "GameSetDetails", id}],
            query: (id: number) => `/gameSets/${id}`,
        }),
        createGameSet: builder.mutation<GameSet, AddGameSetDTO>({
            invalidatesTags: (result) => [
                ...(result?.gameSetId !== undefined ? [{type: "GameSetDetails" as const, id: result.gameSetId}] : []),
                {type: "GameSet", id: result?.gameSetId ?? "ERROR"},
                {type: "GameSet", id: "LIST"},
                ...result?.games?.map((g) => ({type: "Game", id: g.id} as const)) ?? [],
                ...result?.accounts?.map((a) => ({type: "Account", id: a.id} as const)) ?? [],
            ],
            query: (body) => ({
                url: `/gameSets/`,
                method: "POST",
                body,
            })
        }),
        updateGameSet: builder.mutation<GameSet, EditGameSetDTO>({
            invalidatesTags: (result, error, {gameSetId: id, games, accounts}) => [
                {type: "GameSetDetails", id},
                {type: "GameSet", id},
                {type: "GameSet", id: "LIST"},
                ...accounts?.map((id) => ({type: "Account", id} as const)) ?? [],
            ],
            query: (body: EditGameSetDTO) => ({
                url: `/gameSets/${body.gameSetId}`,
                method: "PUT",
                body: {...body, accounts: null, games: null},
            })
        }),
        updateGameSetGames: builder.mutation<void, Pick<GameSet, "gameSetId"> & {gameIds: CollectionUpdate<number>}>({
            invalidatesTags: (result, error, {gameSetId}) => [
                {type: "GameSetDetails", id: gameSetId},
                {type: "GameSet", id: gameSetId},
            ],
            query: (body) => ({
                url: `/gameSets/${body.gameSetId}/games`,
                method: "PATCH",
                body: {...(body.gameIds ?? CollectionUpdate.empty())}
            })
        }),
        updateGameSetAccounts: builder.mutation<void, Pick<GameSet, "gameSetId"> & {userIds: CollectionUpdate<number>}>({
            invalidatesTags: (result, error, {gameSetId, userIds}) => [
                {type: "GameSetDetails", id: gameSetId},
                {type: "GameSet", id: gameSetId},
                ...((userIds.added ?? []).concat(userIds.deleted ?? []).map((id) => ({type: "AccountGameSets" as const, id})))
            ],
            query: (body) => ({
                url: `/gameSets/${body.gameSetId}/users`,
                method: "PATCH",
                body: {...(body.userIds ?? CollectionUpdate.empty())}
            })
        }),
        removeGameSet: builder.mutation<void, GameSet>({
            invalidatesTags: (result, error, {gameSetId: id, games, accounts}) => [
                {type: "GameSetDetails", id},
                {type: "GameSet", id},
                {type: "GameSet", id: "LIST"},
                ...games?.map?.((g) => ({type: "Game", id: g.id} as const)) ?? [],
                ...accounts?.map?.((a) => ({type: "Account", id: a.id} as const)) ?? [],
            ],
            query: (body) => ({
                url: `/gameSets/${body.gameSetId}`,
                method: "DELETE",
            })
        }),
        getLobbyTabs: builder.query<ListResult<LobbyTab>, void>({
            providesTags: (result) => {
                const type = "LobbyTab";
                const resultIds = result?.data.map(({tabId}) => ({type, id: tabId} as const));
                // TODO: include games in here too?
                if (resultIds) {
                    return [...resultIds, {type, id: "LIST"}]
                }
                return [{type, id: "LIST"}];
            },
            query: () => `/tabs`,
        }),
        getLobbyTabById: builder.query<GameSet, number>({
            providesTags: (result, error, id) => [{type: "GameSet", id}],
            query: (id: number) => `/tabs/${id}`,
        }),
        createLobbyTab: builder.mutation<LobbyTab, CreateLobbyTab>({
            // TODO: include games in here too?
            invalidatesTags: (result) => [{type: "LobbyTab", id: result?.tabId ?? "ERROR"}, {
                type: "LobbyTab",
                id: "LIST"
            }],
            query: (body) => ({
                url: `/tabs/`,
                method: "POST",
                body,
            })
        }),
        updateLobbyTab: builder.mutation<LobbyTab, EditLobbyTab>({
            // TODO: include games in here too?
            invalidatesTags: (result, error, {tabId: id}) => [{type: "LobbyTab", id}, {type: "LobbyTab", id: "LIST"}],
            query: (body) => ({
                url: `/tabs/${body.tabId}`,
                method: "PUT",
                body,
            })
        }),
        removeLobbyTab: builder.mutation<void, LobbyTab>({
            // TODO: include games in here too?
            invalidatesTags: (result, error, {tabId: id}) => [{type: "LobbyTab", id}, {type: "LobbyTab", id: "LIST"}],
            query: (body) => ({
                url: `/tabs/${body.tabId}`,
                method: "DELETE",
            })
        }),
        moveGameToLobbyTab: builder.mutation<void, { game: Omit<AdminGame, "newUntil">, tab: LobbyTab }>({
            invalidatesTags: (result, error, arg) => [
                {type: "LobbyTab", id: arg.tab.tabId},
                {type: "Game", id: arg.game.id},
                {type: "LobbyTab", id: "LIST"},
                {type: "Game", id: "LIST"},
            ],
            query: ({game, tab}) => ({
                url: `/tabs/${tab.tabId}/games/${game.id}`,
                method: "PUT",
            })
        }),
        removeGameFromLobbyTab: builder.mutation<void, { game: Omit<AdminGame, "newUntil">, tab: LobbyTab }>({
            invalidatesTags: (result, error, arg) => [
                {type: "LobbyTab", id: arg.tab.tabId},
                {type: "Game", id: arg.game.id},
                {type: "LobbyTab", id: "LIST"},
                {type: "Game", id: "LIST"},
            ],
            query: ({game, tab}) => ({
                url: `/tabs/${tab.tabId}/games/${game.id}`,
                method: "DELETE",
            }),
        }),
    })
});

// transform StoredAdminGame -> AdminGame as part of the hooks, and expose it like a standard query hook
export const useGetGamesQuery = (...args: Parameters<typeof adminApi.useGetGamesQuery>): ReturnType<typeof adminApi.useGetGamesQuery> => {
    const {data, ...rest} = adminApi.useGetGamesQuery(...args);
    const games = useMemo<AdminGame[] | undefined>(() => data?.data.map(storedAdminGameToAdminGame), [data]);
    return {
        data: data !== undefined
            ? {data: games, totalCount: data.totalCount}
            : undefined,
        ...rest
    };
}

export const useGetGameByIdQuery = (...args: Parameters<typeof adminApi.useGetGameByIdQuery>): ReturnType<typeof adminApi.useGetGameByIdQuery> => {
    const {data, ...rest} = adminApi.useGetGameByIdQuery(...args);
    const game = useMemo<AdminGame | undefined>(() => data && storedAdminGameToAdminGame(data), [data]);
    return {
        data: game,
        ...rest
    }
}

export const {
    useUpdateGameSetAccountsMutation,
    useUpdateGameSetGamesMutation,
    useAddGameSetToAccountMutation,
    useRemoveGameSetFromAccountMutation,
    useUpdateGamesInAccountMutation,
    useGetCustomersQuery,
    useGetGamesServersQuery,
    useGetGamesCustomersQuery,
    useGetGamesFormatsQuery,
    useGetGamesForAccountQuery,
    useGetGameSetsForAccountQuery,
    useLazyGetGamesQuery,
    useLazyGetGameByIdQuery,
    useCreateGameMutation,
    useUpdateGameMutation,
    useRemoveGameMutation,
    useGetAccountsQuery,
    useLazyGetAccountsQuery,
    useGetAccountByIdQuery,
    useGetAllRolesQuery,
    useGetAccountRoleQuery,
    useLazyGetAccountByIdQuery,
    useCreateAccountMutation,
    useUpdateAccountMutation,
    useRemoveAccountMutation,
    useGetExclusionsQuery,
    useLazyGetExclusionsQuery,
    useGetExclusionByUserIdQuery,
    useLazyGetExclusionByUserIdQuery,
    useRemoveExclusionMutation,
    useGetGameSetsQuery,
    useGetGameSetByIdQuery,
    useCreateGameSetMutation,
    useUpdateGameSetMutation,
    useRemoveGameSetMutation,
    useGetLobbyTabsQuery,
    useGetLobbyTabByIdQuery,
    useCreateLobbyTabMutation,
    useUpdateLobbyTabMutation,
    useRemoveLobbyTabMutation,
    useMoveGameToLobbyTabMutation,
    useRemoveGameFromLobbyTabMutation,
} = adminApi;
