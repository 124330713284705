import {useGetGamesQuery, useLazyGetGamesQuery} from '../../apis/lobby-api';
import {useMemo} from 'react';
import Game, {storedGameToGame} from '../../../models/game';
import {FetchBaseQueryError} from '@reduxjs/toolkit/query';
import {SerializedError} from '@reduxjs/toolkit';
type QueryError = FetchBaseQueryError | SerializedError | undefined;

export function useLobbyGamesQuery(server: string, customer: string): readonly [games: ReadonlyArray<Game> | undefined, api: {error?: QueryError, isLoading: boolean}] {
    const {data, error, isLoading} = useGetGamesQuery({customer, server});

    const games: ReadonlyArray<Game> | undefined = useMemo(() => data?.map(storedGameToGame), [data]);

    return [games, {error, isLoading}] as const;
}

export function useLobbyGamesLazyQuery(): readonly [getGames: (args: {server: string, customer: string}) => void, api: {games: ReadonlyArray<Game> | undefined, error?: QueryError, isLoading: boolean}] {
    const [getGames, {data, isFetching, error}] = useLazyGetGamesQuery();

    const games: ReadonlyArray<Game> | undefined = useMemo(() => data?.map(storedGameToGame), [data]);

    return [getGames, {games, error, isLoading: isFetching}] as const;
}
