import {FunctionComponent, HTMLAttributes} from "react";
import {Box, BoxProps} from "@mui/material";

export type TabPanelProps = {
    /**
     * Index for this tab
     */
    tabIndex: number;
    /**
     * Currently active tab's index
     */
    currentTabIndex: number;
} & BoxProps;

/**
 * Convenience wrapper for use with {@see Tabs}/{@see Tab}.
 *
 * This is used by {@see TabSet} and you probably won't need to use it directly
 */
export const TabPanel: FunctionComponent<TabPanelProps & HTMLAttributes<HTMLDivElement>> = ({
                                                                                                tabIndex,
                                                                                                currentTabIndex,
                                                                                                children,
                                                                                                ...props
                                                                                            }) => (
    <Box
        role="tabpanel"
        hidden={currentTabIndex !== tabIndex}
        {...props}
    >
        {currentTabIndex === tabIndex && children}
    </Box>
)