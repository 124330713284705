import {Alert, AlertProps, Box, Divider, Paper, Typography} from "@mui/material";
import React, {FunctionComponent} from "react";
import {FetchBaseQueryError} from "@reduxjs/toolkit/query";
import {SerializedError} from "@reduxjs/toolkit";
import {
    isFetchBaseQueryCustomError,
    isFetchBaseQueryFetchError,
    isFetchBaseQueryHTTPError,
    isFetchBaseQueryParsingError
} from "../utils/rtk-query-error-parsing";
import {APIError} from "../models/api-error";


export type QueryErrorAlertProps = Omit<AlertProps, "children"> & {
    message: string;
    error: FetchBaseQueryError | SerializedError | string;
}

function constructErrorMessage(error: FetchBaseQueryError | SerializedError | string): {error: string, message?: string, details?: string, type: string} {
    if (typeof error === "string") {
        return {
            error,
            type: "unknown",
        }
    }
    if (isFetchBaseQueryHTTPError(error)) {
        return {
            error: `${error.status}`,
            message: (error.data as Partial<APIError>)?.title,
            details: JSON.stringify(error.data, null, 2),
            type: "http",
        }
    }
    if (isFetchBaseQueryFetchError(error)) {
        return {
            error: error.error,
            details: error.error,
            type: "fetch",
        }
    }
    if (isFetchBaseQueryParsingError(error)) {
        return {
            error: `${error.originalStatus}`,
            details: error.data,
            type: "parsing",
        }
    }
    if (isFetchBaseQueryCustomError(error)) {
        return {
            error: error.error,
            details: error.data ? JSON.stringify(error.data, null, 2) : undefined,
            type: "custom"
        }
    }
    return {
        error: error.message ?? "Unknown error",
        details: JSON.stringify({...error}, null, 2),
        type: error.name ?? "unknown"
    }
}

export const QueryErrorAlert: FunctionComponent<QueryErrorAlertProps> = ({message, error}) => {
    const {error: errorType, message: errorMessage, details} = constructErrorMessage(error);
    return (
        <>
            <Alert severity="error">
                {message} ({errorType}){errorMessage ? `: ${errorMessage}` : undefined}
            </Alert>
            {process.env.NODE_ENV !== "production" && details && (
                <Paper
                    elevation={2}
                    sx={{
                        marginTop: 2,
                        padding: 1,
                    }}
                >
                    <Typography
                        sx={{
                            margin: 1,
                        }}
                    >
                        Error details only shown in development mode:
                    </Typography>
                    <Divider />
                <Box
                    component="pre"
                    sx={{
                        maxWidth: "100%",
                        overflow: "auto",
                    }}
                >
                    {details}
                </Box>
                </Paper>
            )}
        </>
    )
}