import {
    Autocomplete,
    AutocompleteProps,
    AutocompleteRenderInputParams,
    AutocompleteValue, CircularProgress,
    TextField
} from '@mui/material';
import * as React from 'react';
import {ChipTypeMap} from '@mui/material/Chip';
import {useField} from 'formik';
import {ReactNode, useMemo} from 'react';

export type AutocompleteFieldProps<T,
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined,
    ChipComponent extends React.ElementType = ChipTypeMap['defaultComponent']> =
    Omit<AutocompleteProps<T, Multiple, DisableClearable, FreeSolo, ChipComponent>,
        "value" | "renderInput" | "options" | "loading"> & {
    /**
     * Field name
     */
    name: string;
    /**
     * The label content
     */
    label: string;
    /**
     * If true, the label is displayed as required and the input element is required.
     *
     * Default: `false`
     */
    required?: boolean;
    /**
     * The helper text content
     */
    helperText?: ReactNode;
    /**
     * Array of options.
     *
     * Undefined is supported for when the options are being loaded, and a loading indicator will be shown
     */
    options: ReadonlyArray<T> | undefined;
    /**
     * If true, the options will be sorted using the default `sort` implementation
     */
    sortOptions?: boolean;
}
/**
 * [Formik]{@link https://formik.org/docs/overview} Field wrapper around MUI's {@link Autocomplete} component.
 *
 * This can be used as an alternative to a Select (as a combo box), or can be used to give suggestions while still
 * allowing other inputs.
 *
 * Unlike the MUI base component, this does not allow for renderInput to be specified, for reasons of simplicity
 */
export const AutocompleteField =
    <T, Multiple extends boolean | undefined,
        DisableClearable extends boolean | undefined,
        FreeSolo extends boolean | undefined,
        ChipComponent extends React.ElementType = ChipTypeMap['defaultComponent']>({
                                                                                       name,
                                                                                       label,
                                                                                       multiple,
                                                                                       options,
                                                                                       required,
                                                                                       sortOptions,
                                                                                       ...props
                                                                                   }: AutocompleteFieldProps<T, Multiple, DisableClearable, FreeSolo, ChipComponent>) => {
        const loading = !options;
        const [field, meta, helpers] = useField<AutocompleteValue<T, Multiple, DisableClearable, FreeSolo>>({
            name,
            multiple,
            required,
        });

        const {setValue} = helpers;
        const {touched, error} = meta;

        const optionsForInput = useMemo(() => (
            sortOptions
                ? options?.slice().sort()
                : options
        ) ?? [], [options, sortOptions]);

        return (
            <Autocomplete
                {...props}
                {...field}
                onChange={(event,value) => {setValue(value); }}
                options={optionsForInput}
                multiple={multiple}
                loading={loading}
                renderInput={(params: AutocompleteRenderInputParams) => (
                    <TextField
                        {...params}
                        name={name}
                        error={touched && !!error}
                        helperText={error}
                        label={label}
                        required={required}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <>
                                    {loading ? <CircularProgress color="inherit" size={20}/> : null}
                                    {params.InputProps.endAdornment}
                                </>
                            )
                        }}
                    />
                )}
            />
        )
    }
