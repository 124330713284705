import React, {FunctionComponent, useEffect, useMemo} from 'react';
import {
    Alert,
    AlertColor,
    Box,
    Button,
    Divider,
    Grid,
    LinearProgress,
    Paper,
    Stack,
    styled,
    Typography,
} from "@mui/material";
import {TextField} from "formik-mui";

import {useLocation, useNavigate} from 'react-router-dom';
import {useLoginMutation} from "../store/apis/lobby-api";
import {PasswordField} from "../components/common/inputs/formik/PasswordField";
import {Form, Formik, Field} from "formik";
import {z} from "zod";
import {toFormikValidationSchema} from "zod-formik-adapter";
import {QueryErrorAlert} from "../components/QueryErrorAlert";
import {useAppSelector} from '../store/hooks/root-hooks';
import {LogoutReason, selectLogoutReason} from '../store/slices/accountSlice';

const LoginSchema = z.object({
    userId: z.string().min(1, {message: "User must not be empty"}),
    password: z.string().min(1, {message: "Password must not be empty"}),
})

const LoginButton = styled(Button)({
    width: "unset",
});


const LoginMessage: FunctionComponent<{ logoutReason?: LogoutReason }> = ({logoutReason}) => {
    const alert = useMemo((): {severity: AlertColor, message: string} => {
        switch (logoutReason) {
            case "logout":
                return {severity: "success", message: "You have successfully logged out."};
            case "expired":
                return {severity: "warning", message: "Your session has expired. Please log in again to continue."};
            case "forbidden":
                return {severity: "error", message: "You do not have access to this page. You have been logged out."};
            default:
                return {severity: "info", message: "Please login to continue."};
        }
    }, [logoutReason]);
    return (
        <Alert severity={alert.severity}>
            {alert.message}
        </Alert>
    );
}

const LoginPage: FunctionComponent = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const from = (location.state as {from?: {pathname?: string}} | undefined)?.from?.pathname || "/";
    const logoutReason = useAppSelector(selectLogoutReason);

    const [login, {error, isSuccess}] = useLoginMutation();

    useEffect(() => {
        error && console.error("Login error:", error);
    }, [error]);

    const initialValues : z.infer<typeof LoginSchema> = {
        userId: "",
        password: "",
    };
    const validationSchema = toFormikValidationSchema(LoginSchema);

    useEffect(() => {
        isSuccess && navigate(from, {replace: true});
    }, [isSuccess, navigate, from])

    return (
        <Grid
            container
            sx={{
                width: "100%",
                height: "100%"
            }}
            alignItems="center"
            justifyContent="center"
        >
            <Grid
                item
                sx={{
                    maxWidth: "100%"
                }}>
                <Paper sx={{
                    flexGrow: 0,
                    margin: 4,
                    padding: 4,
                }}>
                    <Box sx={{
                        textAlign: "center",
                    }}>
                        <Typography variant="h2">
                            Welcome
                        </Typography>
                        <Divider sx={{
                            marginBottom: 2,
                        }}/>
                        <LoginMessage logoutReason={logoutReason} />
                    </Box>
                    <Formik
                        initialValues={initialValues}
                        validateOnMount
                        onSubmit={(values, {setSubmitting}) => {
                            setSubmitting(true);
                            login(values).then((result) => {
                                if ("error" in result) {
                                    // only do this on errors, as on success, we can be unmounted when this happens,
                                    // which is bad
                                    setSubmitting(false);
                                }
                            })
                        }}
                        validationSchema={validationSchema}
                    >
                        {({submitForm, isSubmitting, isValid}) => (
                            <Form>
                                <Stack>
                                    <Field
                                        component={TextField}
                                        sx={{margin: 1}}
                                        id="login-user"
                                        name="userId"
                                        label="User"
                                        variant="outlined"
                                    />
                                    <PasswordField
                                        sx={{margin: 1}}
                                        id="login-password"
                                        name="password"
                                        label="Password"
                                        variant="outlined"
                                    />
                                    {isSubmitting && <LinearProgress/>}
                                    <LoginButton
                                        color="primary"
                                        variant="contained"
                                        fullWidth
                                        onClick={submitForm}
                                        disabled={isSubmitting || !isValid}
                                        sx={{margin: 1}}
                                        type="submit"
                                    >
                                        Login
                                    </LoginButton>
                                    {error && (
                                        <QueryErrorAlert
                                            message="Login failed"
                                            error={error}
                                        />
                                    )}
                                </Stack>
                            </Form>
                        )}
                    </Formik>
                </Paper>
            </Grid>
        </Grid>
    );
}

export default LoginPage;
