import {FunctionComponent, useEffect, useState} from "react";
import {Button, FormGroup, FormGroupProps} from "@mui/material";
import {ServerSelect} from "./ServerSelect";
import {CustomerSelect} from "./CustomerSelect";
import {EnvironmentOptions} from "../../../store/slices/lobbySlice";
import {useBreakpoints} from '../../../hooks/use-breakpoints';

export type EnvironmentSelectProps = {
    value: Partial<EnvironmentOptions>
} & ({
    doPartialUpdates?: false | undefined;
    buttonLabel?: string;
    onEnvironmentChange: (value: EnvironmentOptions) => void;
} | {
    doPartialUpdates: true;
    buttonLabel?: undefined;
    onEnvironmentChange: (value: Partial<EnvironmentOptions>) => void;
})

export const EnvironmentSelect: FunctionComponent<EnvironmentSelectProps & Omit<FormGroupProps, "row">> = ({
                                                                                                               buttonLabel = "Go",
                                                                                                               value,
                                                                                                               onEnvironmentChange,
                                                                                                               doPartialUpdates,
                                                                                                               ...props
                                                                                                           }) => {
    const {isNarrow} = useBreakpoints();
    const horizontalMargin = isNarrow ? 0 : 0.5;
    const [server, setServer] = useState(value?.server ?? "");
    const [customer, setCustomer] = useState(value?.customer ?? "");
    const [isDirty, setIsDirty] = useState(false);
    const isValid = server && customer;
    const handleServerChange = (value: string) => {
        if (server !== value) {
            setIsDirty(true);
            setServer(value);
        }
    }
    const handleCustomerChange = (value: string) => {
        console.log("customer changed to", value)
        if (customer !== value) {
            setIsDirty(true);
            setCustomer(value);
        }
    }
    const handleClick = () => {
        if (server && customer) {
            onEnvironmentChange({server, customer});
            setIsDirty(false);
        }
    }
    useEffect(() => {
        if (doPartialUpdates) {
            onEnvironmentChange({server, customer});
        }
    }, [doPartialUpdates, server, customer, onEnvironmentChange]);

    return (
        <FormGroup
            row
            sx={{
                marginBottom: 1,
                justifyContent: isNarrow ? "space-around" : undefined,
            }}
            {...props}
        >
            <ServerSelect
                label="Server"
                value={server}
                onValueChange={handleServerChange}
                sx={{
                    marginRight: horizontalMargin,
                    maxWidth: isNarrow ? "33%" : 0,
                    minWidth: isNarrow ? "40%" : "10em"
                }}
            />
            <CustomerSelect
                label="Customer"
                server={server}
                value={customer}
                onValueChange={handleCustomerChange}
                sx={{
                    marginLeft: horizontalMargin,
                    marginRight: horizontalMargin,
                    maxWidth: isNarrow ? "43%" : 0,
                    minWidth: isNarrow ? "50%" : "10em"
                }}
            />
            {!doPartialUpdates && (
                <Button
                    disabled={!isValid || !isDirty}
                    variant="contained"
                    onClick={handleClick}
                    sx={{
                        flex: "1 1 auto",
                        marginLeft: horizontalMargin,
                    }}
                >
                    {buttonLabel}
                </Button>
            )}
        </FormGroup>
    )
}
