import {serverConfigForCustomerServer} from "../constants/constants";
import {z} from 'zod';
import {RetypeProperty} from '../types/utils';
import {parseISO} from 'date-fns';
import {memoize} from "lodash";

export const AdminGameSchema = z.object({
    id: z.number(),
    name: z.string(),
    format: z.string(),
    gamepath: z.string(),
    imgpath: z.string(),
    resx: z.number().gt(0, "Must be > 0").int("Must be a whole number"),
    resy: z.number().gt(0, "Must be > 0").int("Must be a whole number"),
    customer: z.string(),
    server: z.string(),
    gameologyEngine: z.boolean(),
    tabId: z.number().optional().nullable(),
    newUntil: z.date().optional().nullable(),
    launchName: z.string().optional().nullable(),
    readableName: z.string().optional().nullable(),
    v3Launch: z.boolean().optional().nullable(),
})

export type AdminGame = z.infer<typeof AdminGameSchema>;

export type StoredAdminGame = RetypeProperty<AdminGame, "newUntil", string | undefined>;

export function storedAdminGameToAdminGame(stored: StoredAdminGame | AdminGame): AdminGame {
    if (typeof stored.newUntil === "string") {
        return {
            ...stored,
            newUntil: stored.newUntil ? parseISO(stored.newUntil) : undefined,
        }
    } else {
        //console.trace("StoredAdminGame might actually be AdminGame?");
        return stored as AdminGame;
    }
}

export const GameSchema = AdminGameSchema.omit({
    resx: true, resy: true, gameologyEngine: true
}).merge(z.object({
    displayName: z.string(),
    launchName: z.string(),
    v3Launch: z.boolean(),
    directLaunchUrl: z.string(),
    loaderLaunchUrl: z.string(),
}));

type Game = z.infer<typeof GameSchema>;
export type StoredGame = RetypeProperty<Game, "newUntil", string | undefined>;
export function storedGameToGame(stored: StoredGame): Game {
    return {
        ...stored,
        newUntil: stored.newUntil ? parseISO(stored.newUntil) : undefined,
    }
}
// type Game =
//     Omit<RenameProperty<RenameProperty<AdminGame, "gamepath", 'gamePath'>, "imgpath", "imgPath">, "resx" | "resy" | "gameologyEngine">
//     & {
//     displayName: string,
//     launchName: string,
//     directLaunchUrl: string,
//     loaderLaunchUrl: string,
// }

export default Game;


export const getReadableGameName = memoize((name: string) => name
    // remove any underscores
    .replace("_", "")
    // capitalise first letter
    .replace(/^[a-z]/, (t) => t.toUpperCase())
    // capitalise v in version tags
    .replace(/(v)\d/, (t) => t.toUpperCase())
    // spaces before caps if not already present
    .replace(/\s?([A-Z][a-z])/g, " $1")
    // spaces before numbers if not already present
    // Lookbehind not supported in browsers that aren't Chrome :(
    //.replace(/((?<![A-Zv\d ])\d)/g, " $1")
    // blame trey: https://regex101.com/r/YylGkI/3
    .replace(/((?! )(([a-z])([0-9]+)|^))/g, "$3 $4")
    // spaces before "tags" (JPK, V2, etc)
    .replace(/(\S)(V\d|JP?K|AWP|VM|MW)/g, "$1 $2")
    // fix special case single letter capitals
    .replace(/([a-z])([AO])/g, "$1 $2")
    // title case
    .replace(/ ([a-z])/g, (t) => t.toUpperCase())
    .trim()
);

export const getLaunchGameName = (path: string) => {
    const result = /BP_(?:MOB)?(.+)/.exec(path);
    if (result) {
        return `BP_${result[1].split("/")[0]}`
    }

    const devResult = /MOB\d+_(.+)/.exec(path);
    if (devResult) {
        const launchName = `BP_${devResult[1].split("/")[0]}`
        console.info(`Launch name for ${path} generated with dev pattern, may be incorrect: ${launchName}`);
        return launchName;
    }
    console.warn(`Could not calculate game launchName for path ${path}!`);
    return "";
}

const getServerUrl = (customer: string, server: string) => {
    const serverUrl = serverConfigForCustomerServer(customer, server).server;
    if (serverUrl.endsWith("/")) {
        return serverUrl;
    }
    return `${serverUrl}/`;
};
export const getDirectLaunchUrl = (path: string, customer: string, server: string) => {
    return `${getServerUrl(customer, server)}${path}`;
};
export const getLoaderLaunchUrl = (customer: string, server: string) => {
    return `${getServerUrl(customer, server)}loader/loader.aspx`
};

export const getLaunchParamsForGame = (game: Game, language: string, token: string, customer: string, realPlay: boolean,
                                       platform: string, serverType: string, launchMode: string, affiliate: string,
                                       devFlag: boolean, useLoader: boolean) => {

    //TODO: use new URLSearchParam() instead of building it manually with strings
    // https://git.blueprintgaming.com/framework-team/lobby-frontend/-/issues/99 Refactor getLaunchParamsForGame

    const getAffilate = () => {
        if(affiliate !== ""){
            return affiliate
        }
        else {
            return "default"
        }
    }



    const baseParams = `?platform=${platform}&playMode=${realPlay ? "real" : "demo"}&language=${language}&affiliate=${getAffilate()}&brand=${getAffilate()}&token=${token}${devFlag ? "&dev&logLevel=dev" : ""}`;

    //default


    if (useLoader && customer !== "dyvo") {
        return `${baseParams}&game=${game.launchName}`
    }
    const directBaseParams = `${baseParams}&serverType=${serverType}&customer=${customer}`;
    if (game.v3Launch) {
        return `${directBaseParams}&gameName=${game.launchName}`;
    }

    // Terrible Hotfix, this needs improving. But for now it'll let our boys at Dyvo work.
    if (customer === "dyvo"){
        const userid = token.split(';')
        const baseParamD = `?platform=${platform}&playMode=${realPlay ? "real" : ""}&language=${language}&affiliate=${affiliate}&token=${token}`
        const directBaseParamsD = `${baseParamD}&game=${game.launchName}&userid=${userid[1]}`;
        return `${directBaseParamsD}&rgscustomer=dyvo&brand=${affiliate}&customer=customerdev`;
    }
    else {
        return `${directBaseParams}&assetsName=${game.launchName}`;
    }
}

export function adminGameToGame({
                                    id,
                                    name,
                                    format,
                                    gamepath,
                                    imgpath,
                                    customer,
                                    server,
                                    tabId,
                                    newUntil,
                                    launchName,
                                    readableName,
                                    v3Launch,
                                }: AdminGame): Game {
    return {
        id,
        name,
        format,
        gamepath,
        imgpath,
        customer,
        server,
        tabId,
        newUntil,
        v3Launch: v3Launch ?? false,
        displayName: readableName || getReadableGameName(name),
        launchName: launchName || getLaunchGameName(gamepath),
        directLaunchUrl: getDirectLaunchUrl(gamepath, customer, server),
        loaderLaunchUrl: getLoaderLaunchUrl(customer, server),
    }
}

export const processGamesForDisplay = (games: ReadonlyArray<Game>, sortAsc?: boolean, allowFavourites?: boolean, favourites?: number[]) => games
    .slice()
    .sort((a: Game, b: Game) => {
        const nameA = a.name.toLowerCase();
        let nameB = b.name.toLowerCase();
        let sortValue = 0;
        if (nameA < nameB) {
            sortValue = -1;
        }
        if (nameA > nameB) {
            sortValue = 1;
        }
        if (allowFavourites && favourites) {
            if (favourites.includes(a.id) && !favourites.includes(b.id)) {
                return -1;
            } else if (favourites.includes(b.id) && !favourites.includes(a.id)) {
                return 1;
            }
        }
        if (!sortAsc) {
            sortValue *= -1;
        }
        return sortValue;
    })

// working:
// ?token=yrRtB1XSAgHf7uY2ftoUnzAr2AOWKw7nbtJfRzlnlF5f0FcDLT;paltham
// &language=NL
// &playmode=real
// &bp_jurisdiction=US
// &brand=us-us
// &affiliate=us
// &customer=BLUEPRINT
// &configUrl=https%3a%2f%2ffileservicergspubst.blueprintgaming.com%3ffileType%3dconfig%26customer%3dBLUEPRINT%26affiliate%3dus%26platform%3dDesktop%26clientFolder%3dBP_WormsReloaded%26serverType%3duat%26bp_jurisdiction%3dUS%26cachebuster%3d1fe0e890-eb6d-4b17-909f-0794057c9a43%26launchdomain%3d-1
// &qBridgeUrlXSS=contentrgspubst%2f%2fcom%2f%2fQBridge%2f1.9.4-hotfix16-game-name-side-bets-4hfqa%2fqBridge.js
// &qBridgeUrl=https%3a%2f%2fcontentrgspubst.blueprintgaming.com%2fQBridge%2f1.9.4-hotfix16-game-name-side-bets-4hfqa%2fqBridge.js
// &serverType=uat
// &tld=0
// &game=BP_WormsReloaded
// &gameName=BP_WormsReloaded
// &assetsName=BP_WormsReloaded
// &platform=Desktop
// &bp_guid=b1035bce-efb7-4771-80b3-35995a4b9dd4

// broken:
// ?platform=desktop
// &playmode=real
// &language=en
// &affiliate=content-us
// &serverType=uat
// &customer=blueprint
// &assetsName=BP_WormsReloaded
// &token=yrRtB1XSAgHf7uY2ftoUnzAr2AOWKw7nbtJfRzlnlF5f0FcDLT;paltham
