import {FunctionComponent, SyntheticEvent} from "react";
import {useGetLanguagesQuery} from "../../store/apis/lobby-api";
import {Autocomplete, AutocompleteProps, CircularProgress, TextField} from "@mui/material";
import {isLabelledValue, LabelledValue} from "../../models/labelled-value";


export interface LanguageSelectProps {
    id: string;
    onChange: (e: SyntheticEvent, value: string | null) => void;
    value: string | null;
    sx: AutocompleteProps<any, any, any, any>["sx"];
}

export const LanguageSelect: FunctionComponent<LanguageSelectProps> = ({id, value, onChange, sx}) => {
    const {data, isLoading} = useGetLanguagesQuery();
    const options = data ?? [];
    const onValueChanged = (e: SyntheticEvent, value: LabelledValue | string | null) => {
        // Autocomplete does something weird with the type of the value, thinks it should be string when it actually
        //  isn't, so grab the actual value when it's an object
        if (isLabelledValue(value)) {
            onChange(e, value.value);
        } else {
            onChange(e, value);
        }
    }
    return (
        <Autocomplete id={id}
                      sx={{minWidth: "12em", ...sx}}
                      freeSolo
                      options={options ?? []}
                      loading={isLoading}
                      onChange={onValueChanged}
                      value={value}
                      renderInput={(params) => (
                          <TextField {...params}
                                     label="Language Override"
                                     InputProps={{
                                         ...params.InputProps,
                                         endAdornment: (<>
                                             {isLoading ? <CircularProgress color="inherit" size={20}/> : null}
                                             {params.InputProps.endAdornment}
                                         </>)
                                     }}
                          />
                      )}
        />
    )
}
