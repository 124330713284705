import React, {ChangeEvent, Fragment, FunctionComponent, useState} from "react";
import {Button, TextField} from "@mui/material";

interface PostMessageProps {
    gameRef : React.RefObject<HTMLIFrameElement>;
}

export const PostMessageButton: FunctionComponent<PostMessageProps> = (props: PostMessageProps) => {
    const [data, setData] = useState("");
    const [type, setType] = useState("");
    const {gameRef} = props

    const typeChange = function (e : ChangeEvent<HTMLInputElement>){
        setType(e.target.value);
    }

    const dataChange = function (e : ChangeEvent<HTMLInputElement>){

        setData(e.target.value)
    }

    const postMessageSubmit = function(){
        if(gameRef?.current) {
            let parsedData: unknown;
            try{
                parsedData = JSON.parse(data);
            } catch {
                parsedData = data.length ? data : undefined
            }
            console.log(parsedData);
            if (typeof parsedData === "object") {
                gameRef.current.contentWindow?.postMessage({type: type, ...parsedData}, "*");
            } else {
                gameRef.current.contentWindow?.postMessage({type: type, data: parsedData}, "*");
            }
        }
    }

    return <Fragment>
        <TextField value={type} type={"string"} onChange={typeChange} label={"PostMessage Type"}/>
        <TextField value={data} type={"string"} onChange={dataChange} label={"PostMessage Data"}/>
        <Button onClick={postMessageSubmit}>Submit</Button>
    </Fragment>
}