import React, {FunctionComponent, useMemo} from "react";
import {useAppDispatch, useAppSelector} from "../store/hooks/root-hooks";
import {selectCurrentUser, selectCurrentUserHasAdminPageAccess} from "../store/slices/accountSlice";
import {Apps, Block, Casino, GroupWork, People, Tab} from "@mui/icons-material";
import {selectConfigGameSetsEnabled, selectConfigTabsEnabled} from '../store/slices/configSlice';
import {NavDrawerItemProps} from "./common/nav-drawer/NavDrawerItem";
import {NavDrawerItemGroupProps} from "./common/nav-drawer/NavDrawerItemGroup";
import {NavDrawer} from "./common/nav-drawer/NavDrawer";
import {setEditID} from "../store/slices/edit-slice";

export interface LobbyAdminDrawerProps {
    open: boolean;
    onClose: () => void;
}

export const LobbyAdminDrawer: FunctionComponent<LobbyAdminDrawerProps> = ({open, onClose}) => {
    const userHasAdminUserAccess = useAppSelector(selectCurrentUserHasAdminPageAccess("users"));
    const userHasAdminGamesAccess = useAppSelector(selectCurrentUserHasAdminPageAccess("games"));
    const userHasAdminExclusionsAccess = useAppSelector(selectCurrentUserHasAdminPageAccess("exclusions"));
    const lobbyTabsEnabled = useAppSelector(selectConfigTabsEnabled);
    const gameSetsEnabled = useAppSelector(selectConfigGameSetsEnabled);
    const user = useAppSelector(selectCurrentUser);
    const dispatch = useAppDispatch();
    const navItems = useMemo(() => {
        const navItems: Array<NavDrawerItemProps | NavDrawerItemGroupProps> = [
            {label: "Lobby", to: "/", icon: <Apps/>},
        ]
        const adminNavGroup: NavDrawerItemGroupProps = {title: "Admin", items: []};
        navItems.push(adminNavGroup);
        if (userHasAdminUserAccess) {
            adminNavGroup.items.push({onClick : () => {dispatch(setEditID(user?.id ?? 0))}, label: userHasAdminGamesAccess ? "Users" : "Edit Account", to: userHasAdminGamesAccess ? "/admin/users" : `/admin/users/edit`, icon: <People/>});
        }
        if (userHasAdminGamesAccess) {
            adminNavGroup.items.push("-", {label: "Games", to: "/admin/games", icon: <Casino/>});
            if (gameSetsEnabled) {
                adminNavGroup.items.push({label: "Game Sets", to: "/admin/gameSets", icon: <GroupWork/>});
            }
            if (lobbyTabsEnabled) {
                adminNavGroup.items.push({label: "Lobby Tabs", to: "/admin/tabs", icon: <Tab/>});
            }
        }
        if (userHasAdminExclusionsAccess) {
            adminNavGroup.items.push("-", {label: "Exclusions", to: "/admin/exclusions", icon: <Block/>});
        }
        return navItems;
    }, [
        gameSetsEnabled,
        lobbyTabsEnabled,
        userHasAdminExclusionsAccess,
        userHasAdminGamesAccess,
        userHasAdminUserAccess,
        user?.id,
        dispatch
    ]);

    return (
        <NavDrawer
            open={open}
            onClose={onClose}
            items={navItems}
            includeAppBarPadding
        />
    )
}
