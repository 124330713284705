import {AsyncStringSelect, AsyncStringSelectProps} from "../../common/inputs/AsyncStringSelect";
import {FunctionComponent, useEffect, useMemo} from "react";
import {useGetServersQuery} from "../../../store/apis/lobby-api";
import * as React from "react";

export interface CustomerSelectProps extends Omit<AsyncStringSelectProps<true>, "loading" | "options"> {
    label: string;
    value?: string;
    server: string | undefined;
    onValueChange: (newValue: string) => void;
}

export function useServerCustomerSelect(server: string | undefined) {
    // skipToken is used to prevent the request being sent when server is undefined
    const {data, isLoading: isCustomerListLoading} = useGetServersQuery();
    const customers = useMemo(() => {
        const customerNames = new Set<string>();

        const currentServer = data?.find?.(({name}) => name === server);
        if(currentServer) {
            currentServer.customers?.forEach?.(({name}) => {
                customerNames.add(name);
            });
        }

        return Array.from(customerNames).sort();
    }, [data, server]);

    return {isCustomerListLoading, customers};
}

export function useAllCustomerSelect() {
    const {data, isLoading: isCustomerListLoading} = useGetServersQuery();

    const customers = useMemo(() => {
        const customerNames = new Set<string>();

        if(data) {
            data.forEach((server) => {
                if(server.customers) {
                    server.customers.forEach(({name}) => {
                        customerNames.add(name);
                    })
                }
            })
        }

        return Array.from(customerNames).sort();
    }, [data]);

    return {isCustomerListLoading, customers};
}

export const CustomerSelect: FunctionComponent<CustomerSelectProps> = ({
                                                                           label,
                                                                           value,
                                                                           server,
                                                                           disabled,
                                                                           onValueChange,
                                                                           ...props
                                                                       }) => {
    const {customers, isCustomerListLoading} = useServerCustomerSelect(server);
    useEffect(() => {
        if (!customers?.includes(value as string)) {
            if (customers.length === 1) {
                onValueChange(customers[0]);
            } else {
                onValueChange("");
            }

        }
    }, [customers, value, onValueChange]);

    const handleValueChanged = (newValue: string | null) => {
        onValueChange(newValue ?? "");
    }

    // disable the input until the server value is set
    const inputDisabled = !server || disabled;

    return (
        <AsyncStringSelect
            label={label}
            options={customers}
            loading={isCustomerListLoading}
            value={value}
            onValueChange={handleValueChanged}
            disabled={inputDisabled}
            //disableClearable={customers?.length <= 1}
            {...props}
            sx={{
                minWidth: "10em",
                ...props.sx,
            }}
        />
    )
}
