import {
    FormControl, FormHelperText,
    IconButton,
    Input,
    InputAdornment,
    InputLabel, InputProps,
    TextFieldProps,
} from "@mui/material";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import React, {FunctionComponent, MouseEvent, useState} from "react";
import {OutlinedInput} from "@mui/material";
import {FilledInput} from "@mui/material";
import {useField} from "formik";

export type PasswordFieldProps = Omit<InputProps, "onChange" | "onBlur" | "value" | "name">
    & Pick<TextFieldProps, "variant" | "label" | "autoComplete">
    & { name: string };

/**
 * MUI {@link TextField} set up with toggleable visibility of the contents, designed for password fields.
 *
 * This is a [Formik]{@link https://formik.org/docs/overview} Field
 */
export const PasswordField: FunctionComponent<PasswordFieldProps> = ({
                                                                         id,
                                                                         label,
                                                                         variant,
                                                                         sx,
                                                                         name,
                                                                         ...props
                                                                     }) => {
    const [showValue, setShowValue] = useState(false);
    const onShowValueMouseDown = (e: MouseEvent) => {
        e.preventDefault();
    }
    const VariantInput = variant === "outlined" ? OutlinedInput : variant === "filled" ? FilledInput : Input;

    const onShowValueClick = () => {
        setShowValue(!showValue);
    }

    const [field, meta] = useField({
        name,
        type: "text",
    });
    return (
        <FormControl sx={sx}>
            <InputLabel htmlFor={id}>{label}</InputLabel>
            <VariantInput
                id={id}
                type={showValue ? 'text' : 'password'}
                {...field}
                autoComplete="currentPassword"
                label={label}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={onShowValueClick}
                            onMouseDown={onShowValueMouseDown}
                            size="large">
                            {showValue ? <Visibility/> : <VisibilityOff/>}
                        </IconButton>
                    </InputAdornment>
                }
                {...props}
            />
            <FormHelperText>{(meta.touched && typeof meta.error === "string") ? meta.error : undefined}</FormHelperText>
        </FormControl>
    )
}

