import {forwardRef, ReactNode} from 'react';
import {GridScrollSeekPlaceholderProps, VirtuosoGrid} from 'react-virtuoso';
import {Skeleton, styled} from '@mui/material';

/**
 * @template T - type of the contents
 */
export interface VirtualizedGridProps<T> {
    /**
     * Items to show in the {@link VirtualizedGrid}
     */
    items: T[];
    /**
     * How many items to draw outside the currently visible items
     */
    overscan?: number;
    /**
     * Number of columns in the grid
     */
    cols: number;
    /**
     * Provide a function to render each grid item
     * @param item
     */
    renderItem: (item: T) => ReactNode;
}

const ItemContainer = styled("div")(({theme}) => ({
    display: "flex",
    flex: "1 1 auto",
    alignContent: "stretch",
}));

interface ListContainerProps {
    /**
     * Number of columns in the grid
     */
    cols: number;
    /**
     * Gap between the grid items (as a multiplier of the MUI `theme.spacing` value
     */
    gap?: number;
}

const ListContainer = styled("div", {
    shouldForwardProp(propName: PropertyKey) {
        return propName !== "cols" && propName !== "gap";
    }
})<ListContainerProps>(({theme, cols, gap = 0}) => ({
    display: "grid",
    gridTemplateColumns: `repeat(${cols}, 1fr)`,
    gap: theme.spacing(gap),
    alignItems: "stretch",
    alignContent: "stretch",
}));

const ScrollSeekPlaceholder = styled(({height, width}: GridScrollSeekPlaceholderProps) => (
    <ItemContainer>
        <Skeleton
            variant="rectangular"
            width={width}
            height={height}
        />
    </ItemContainer>
))(({theme}) => ({}));

/**
 * Virtualize a CSS grid
 *
 * While this does not wrap MUI components, the MUI style/theme system is used
 *
 * @typeParam T - The type of the items shown by the grid
 */
export const VirtualizedGrid = <T, >({
                                         items,
                                         overscan = 10,
                                         renderItem,
                                         cols,
                                         gap,
                                     }: VirtualizedGridProps<T> & ListContainerProps) => (
    <VirtuosoGrid
        totalCount={items.length}
        overscan={overscan}
        itemContent={(index: number) => renderItem(items[index])}
        components={{
            Item: ItemContainer,
            List: forwardRef<HTMLDivElement>((props, ref) => (
                    <ListContainer
                        cols={cols}
                        gap={gap}
                        {...props}
                        ref={ref}
                    />
                )
            ),
            ScrollSeekPlaceholder,
        }}
    />
)
