import {FunctionComponent, useEffect} from 'react';
import { Navigate } from 'react-router-dom';
import {useLogoutMutation} from '../store/apis/lobby-api';
import {CircularProgress} from '@mui/material';
import {useAppSelector} from '../store/hooks/root-hooks';
import {selectCurrentUser} from '../store/slices/accountSlice';

export const LogoutPage: FunctionComponent = () => {
    const [logout] = useLogoutMutation();
    const user = useAppSelector(selectCurrentUser);
    useEffect(() => {
        logout();
    }, [logout]);
    return !!user
        ? <Navigate to="/login" />
        : <CircularProgress />
}
