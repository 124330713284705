import {InputBase, styled, alpha} from '@mui/material';
import {FunctionComponent, FocusEvent, KeyboardEvent, ChangeEvent} from 'react';
import SearchIcon from '@mui/icons-material/Search';
import {useDebounce} from "../../../hooks/use-debounce";


const Search = styled('div')(({theme}) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({theme}) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({theme}) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
}));

export interface AppBarSearchProps {
    /**
     * Callback when the search is executed
     *
     * This is called on blur
     * @param search - the new search string
     */
    onSearch: (search?: string) => void;
    /**
     * Placeholder text to display in the field
     *
     * Default = "Search..."
     */
    placeholder?: string;
    /**
     * Milliseconds to debounce the onSearch callback by. Default is 100
     */
    debounceInputMs?: number;
}

/**
 * Search field for use with a MUI {@link AppBar}
 *
 * This is based on the [MUI docs]{@link https://mui.com/components/app-bar/#app-bar-with-search-field}
 */
export const AppBarSearch: FunctionComponent<AppBarSearchProps> = ({
                                                                       onSearch,
                                                                       placeholder = "Search...",
                                                                       debounceInputMs = 100,
                                                                   }) => {
    const onBlur = (e: FocusEvent<HTMLInputElement>) => {
        const searchValue = e.target.value;
        onSearch(searchValue || undefined);
    };
    const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            const searchValue = e.currentTarget.value;
            onSearch(searchValue || undefined);
        }
    }

    const onInputChange = useDebounce((e: ChangeEvent<HTMLInputElement>) => {
        const searchValue = e.target.value;
        onSearch(searchValue || undefined)
    }, debounceInputMs);
    return (
        <Search>
            <SearchIconWrapper>
                <SearchIcon/>
            </SearchIconWrapper>
            <StyledInputBase
                placeholder={placeholder}
                inputProps={{'aria-label': 'search'}}
                onBlur={onBlur}
                onKeyDown={onKeyDown}
                onChange={onInputChange}
            />
        </Search>
    )
}

