import at from "lodash/at";
import {Middleware} from "@reduxjs/toolkit";

export const createPersistLobbySettingsMiddleware = (pathsToPersist: string[]): Middleware => (store) => {
    let prevState: any | undefined;
    let hasLoadedLocalStorage = false;
    return function wrapDispatch(next) {
        return function handleAction(action) {
            // on initial load, get values from localStorage and then dispatch a persist/load action to get them into the store
            if (!hasLoadedLocalStorage) {
                hasLoadedLocalStorage = true;
                console.log("loading state from local storage")
                const payload = pathsToPersist.reduce<Record<string, unknown>>((obj, path) => {
                    const lobbyPath = `lobby.${path}`;
                    const value = localStorage.getItem(lobbyPath);
                    console.log(lobbyPath, value);
                    if (value !== undefined && value !== null) {
                        obj[path] = JSON.parse(value);
                    }
                    return obj;
                }, {});
                const loadAction = {type: "persist/load", payload}
                next(loadAction);
            }
            // dispatch the action
            const result = next(action);
            // get the state after the action has been dispatched
            const state = store.getState();
            pathsToPersist.forEach((path) => {
                const lobbyPath = `lobby.${path}`;
                const prevValue = at(prevState ?? {}, lobbyPath)[0];
                const newValue = at(state, lobbyPath)[0];
                //console.log(lobbyPath, newValue);
                // if the value we're monitoring changed, then store them in local storage
                if (prevValue !== newValue && newValue !== null && newValue !== undefined) {
                    localStorage.setItem(lobbyPath, JSON.stringify(newValue))
                }
            });
            // store the state to check against next time
            prevState = state;
            return result;
        }
    }
}