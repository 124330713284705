export interface ICollectionUpdate<TData> {
    deleted: TData[];
    added: TData[];
}

type DataChangeComparer<TData> = (initial: TData, changed: TData) => boolean;

export class CollectionUpdate<TData> implements ICollectionUpdate<TData> {
    public static empty<TData>() { return Object.freeze<ICollectionUpdate<TData>>({deleted: [], added: []}); }

    public constructor(initial: TData[], changed: TData[], compareFn: DataChangeComparer<TData> = (initial, changed) => initial === changed) {
        this.added = changed.filter((newElement) => !initial.some((oldElement) => compareFn(oldElement, newElement)));
        this.deleted = initial.filter((oldElement) => !changed.some((newElement) => compareFn(oldElement, newElement)));
    }

    public deleted: TData[] = [];
    public added: TData[] = [];
}