import React, {FunctionComponent, SyntheticEvent, useEffect, useState} from "react";
import {useGetServersQuery} from "../../store/apis/lobby-api";
import {useAppSelector} from "../../store/hooks/root-hooks";
import {
    selectAffiliatesForCurrentCustomer,
    selectGameLaunchOptions
} from "../../store/slices/lobbySlice";
import {Autocomplete, CircularProgress, TextField} from "@mui/material";
import {LabelledValue} from "../../models/labelled-value";

export interface AffiliateSelectProps {
    id: string;
    onChange: (e: SyntheticEvent, value: string | null) => void;
}
const loadingValue = {label: "", value: "disabled"};
export const AffiliateSelect: FunctionComponent<AffiliateSelectProps> = ({id, onChange}) => {
    const {isLoading} = useGetServersQuery();
    const data = useAppSelector(selectAffiliatesForCurrentCustomer);
    const {affiliate} = useAppSelector(selectGameLaunchOptions);
    const [value, setValue] = useState<LabelledValue>(loadingValue);
    const onValueChanged = (e: SyntheticEvent, value: LabelledValue) => {
        setValue(value);
        onChange(e, value?.value ?? null)
    }
    useEffect(() => {
        const initialValue = data?.find((value) => value.value === affiliate) ?? loadingValue;
        setValue(initialValue)
    }, [affiliate, data]);

    return (
        <Autocomplete id={id}
                      sx={{minWidth: "10em"}}
                      disableClearable
                      options={data ?? []}
                      loading={isLoading}
                      disabled={!data?.length}
                      getOptionDisabled={(option) => option.value === "disabled"}
                      onChange={onValueChanged}
                      value={value}
                      isOptionEqualToValue={(option, value) => option.value === value.value}
                      renderInput={(params) => (
                          <TextField {...params}
                                     label="Affiliate"
                                     InputProps={{
                                         ...params.InputProps,
                                         endAdornment: (<>
                                             {isLoading ? <CircularProgress color="inherit" size={20}/> : null}
                                             {params.InputProps.endAdornment}
                                         </>)
                                     }}
                          />
                      )}
        />
    )
}
