import {FunctionComponent} from "react";
import {IconButton, IconButtonProps} from "@mui/material";

export interface ToggleIconButtonProps {
    /**
     * Current value
     */
    value: boolean;
    /**
     * Optional colour to use when value is true
     */
    colourTrue?: string;
    /**
     * Optional colour to use when value is false
     */
    colourFalse?: string;
    /**
     * Icon to use when the value is true
     */
    iconTrue: JSX.Element;
    /**
     * Icon to use when the value is false
     */
    iconFalse: JSX.Element;
    /**
     * Callback for when the should be changed
     */
    onChange: (newValue: boolean) => void;
}

/**
 * {@link IconButton} that acts like a switch, toggling between icons (and optionally colours)
 */
export const ToggleIconButton: FunctionComponent<ToggleIconButtonProps
    & Omit<IconButtonProps, "onClick" | "children" | "value" | "onChange">> = ({
                                                                                   iconTrue,
                                                                                   colourTrue,
                                                                                   iconFalse,
                                                                                   colourFalse,
                                                                                   value,
                                                                                   onChange,
                                                                                   sx,
                                                                                   ...props
                                                                               }) => {
    const onClick = () => {
        onChange(!value);
    }
    const colour = (value ? colourTrue : colourFalse);
    return (
        <IconButton
            onClick={onClick}
            sx={{
                color: colour, ...sx
            }}
            {...props}
        >
            {value ? iconTrue : iconFalse}
        </IconButton>
    )
}
