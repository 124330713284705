import {useAppSelector} from '../store/hooks/root-hooks';
import {checkPermissionsForUser, selectCurrentUser} from '../store/slices/accountSlice';
import {useCheckLoginQuery, useLoginMutation, useLogoutMutation} from '../store/apis/lobby-api';
import {useCallback} from "react";
import {LobbyPermissions} from "../constants/lobby-permissions";
import {LoginAccount} from "../models/account";
import {UseMutationResult} from "../types/use-query-result";
import {FetchBaseQueryError} from '@reduxjs/toolkit/query';

export interface UseAuthResult {
    /**
     * The currently logged in user, or undefined if not currently logged in
     */
    user: LoginAccount | undefined;
    /**
     * Has the initial check to see if the current user is logged in already been completed?
     */
    isInitialised: boolean;
    /**
     * Has the session expired?
     *
     * This is set when the login check hits either a 401 or 403 error
     */
    sessionExpired: boolean;
    /**
     * Login to an account with a userId (name) and password
     * @param data.userId - the user name
     * @param data.password - the password
     */
    login: (data: {userId: string, password: string}) => void;
    /**
     * Logout from the currently logged in account
     */
    logout: () => void;
    /**
     * Check whether the user has a specific permission
     * @param permission - the permission to check
     */
    checkPermission: (permission: LobbyPermissions) => boolean;
    /**
     * The result object for the login mutation
     *
     * This is taken from the tuple returned by RTKQuery's generated useMutation hook
     *
     * The results should not usually be required, but are provided for completeness
     */
    loginResult: UseMutationResult<LoginAccount>;
    /**
     * The result object for the logout mutation
     *
     * This is taken from the tuple returned by RTKQuery's generated useMutation hook
     */
    logoutResult: UseMutationResult<unknown>;
}

/**
 * Use the RGS Lobby user
 *
 * This hook wraps various redux values to provide login/logout and current account behaviours
 */
export function useAuth(): UseAuthResult {
    const user = useAppSelector(selectCurrentUser);

    // set up login polling to check for session expiry
    const initialSessionCheckResult = useCheckLoginQuery();
    const isInitialised = !initialSessionCheckResult.isFetching; //initialSessionCheckResult.isSuccess;
    const sessionExpired = [401, 403].includes((initialSessionCheckResult.error as FetchBaseQueryError)?.status as number)
    const [logout, logoutResult] = useLogoutMutation();
    const [login, loginResult] = useLoginMutation();

    const checkPermission = useCallback((permission: LobbyPermissions) =>
        checkPermissionsForUser(user, permission),
        [user]);

    return {
        user,
        isInitialised,
        sessionExpired,
        login,
        logout,
        checkPermission,
        loginResult,
        logoutResult,
    };
}
