import React, {FunctionComponent} from 'react';
import {useAppSelector} from '../store/hooks/root-hooks';
import {selectGameButtonSize} from '../store/slices/configSlice';
import {selectAbsoluteGameImagePath} from '../store/misc-selectors';
import Game from '../models/game';
import ImageComponent from '@jy95/material-ui-image';

export interface GameImageProps {
    game: Game;
}

export const GameImage: FunctionComponent<GameImageProps> = ({game}) => {
    const {
        width,
        height,
    } = useAppSelector(selectGameButtonSize) ?? {};
    const imgPath = useAppSelector(selectAbsoluteGameImagePath(game.imgpath));

    return (
        <GameImagePreview
            imgPath={imgPath}
            width={width}
            height={height}
        />
    )
}

export interface GameImagePreviewProps {
    imgPath: string | undefined
    width: number;
    height: number;
}

export const GameImagePreview: FunctionComponent<GameImagePreviewProps> = ({imgPath, width = 100, height = 100}) => (
    <ImageComponent
        src={imgPath ?? ""}
        color="transparent"
        aspectRatio={width / height}
    />
)
