import React, {FunctionComponent, SyntheticEvent, useEffect, useMemo} from 'react';
import {GamesList} from '../components/GamesList';
import {useAppDispatch, useAppSelector} from "../store/hooks/root-hooks";
import {
    changeAffiliate,
    changeEnvironment,
    selectFavouriteGames,
    selectLobbyEnvironmentOptions, selectLobbyGameViewSortAscending, showLobbyAppBarOptions
} from "../store/slices/lobbySlice";
import {LobbyOptions} from "../components/lobby-options/LobbyOptions";
import {processGamesForDisplay} from '../models/game';
import {
    selectConfigDevOptionsEnabled,
    selectConfigFavouritesEnabled,
    selectConfigTabsEnabled
} from '../store/slices/configSlice';
import {LobbyTabs} from "../components/LobbyTabs";
import {useLobbyGamesLazyQuery} from '../store/hooks/api-convenience-hooks/lobby';
import {useGlobalLoadingIndicator} from '../PageLayout';
import {AffiliateSelect} from "../components/inputs/AffiliateSelect";
import styled from "@emotion/styled";

export const AffilateSelector = styled.div`
    padding-top: 1%;
`

export const LobbyPage: FunctionComponent = () => {
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(showLobbyAppBarOptions(true));
        return () => {
            dispatch(showLobbyAppBarOptions(false));
        }
    }, [dispatch]);

    const {customer, server} = useAppSelector(selectLobbyEnvironmentOptions);
    const sortAscending = useAppSelector(selectLobbyGameViewSortAscending);
    const allowFavourites = useAppSelector(selectConfigFavouritesEnabled);
    const devOptions = useAppSelector(selectConfigDevOptionsEnabled);
    const favourites = useAppSelector(selectFavouriteGames);
    const [getGames, {games, isLoading: isLoadingGames}] = useLobbyGamesLazyQuery();
    const showLoadingIndicator = useGlobalLoadingIndicator();
    showLoadingIndicator(isLoadingGames);

    const tabsEnabled = useAppSelector(selectConfigTabsEnabled);

    useEffect(() => {
        if (customer && server) {
            getGames({customer, server})
        }
    }, [getGames, customer, server]);

    useEffect(() => {
        if(!devOptions && window.location.hostname.includes("demo")){
            dispatch(changeEnvironment({customer : "blueprint", server : "demo"}));
        }
    },[devOptions, dispatch])


    const gamesDisplayList = useMemo(() =>
            (games && processGamesForDisplay(games, sortAscending, allowFavourites, favourites)) ?? [],
        [games, sortAscending, allowFavourites, favourites]);

    const onAffiliateChanged = (e: SyntheticEvent, value: string | null) => {
        if (value) {
            dispatch(changeAffiliate(value));
        }
    };

    const GamesDisplay = tabsEnabled ? LobbyTabs : GamesList;
    return (
        <>
            {devOptions && !window.location.hostname.includes("demo") ? <LobbyOptions/> : <AffilateSelector><AffiliateSelect id="launch-option-affiliate"
                                                                                                                        onChange={onAffiliateChanged}
            /></AffilateSelector> }
            {games && customer && server && (
                <GamesDisplay games={gamesDisplayList} />
            )}
        </>
    )
}

export default LobbyPage;
