import {PermissionsSchema} from "./permissions";
import {z} from "zod";

export const WalletAccountSchema = z.object({
    id: z.number(),
    currency: z.string(),
    language: z.string(),
    country: z.string(),
    balanceCash: z.number(),
    balanceFreebet: z.number(),
    cookie: z.string(),
    defaultCustomer: z.string(),
    realityCheckIntervalSecs: z.number().or(z.null()).optional(),
    email: z.string().email().or(z.null()).optional(),
    accessLevel: z.number().optional(),
    userId: z.string()
});

export type WalletAccount = z.infer<typeof WalletAccountSchema>;

export const AdminAccountSchema = z.object({
    id: z.number(),
    userName: z.string().optional(),
    email: z.string().email().or(z.null()).optional(),
    password: z.string(),
    creationDate: z.string(),
    loggedIn: z.boolean(),
    sessionExpiry: z.string(),
    sessionStartTime: z.string(),
    permissions: PermissionsSchema.optional(),
    wallet: WalletAccountSchema.or(z.null()).optional()
});

export type AdminAccount = z.infer<typeof AdminAccountSchema>;

export const AdminAccountListEntrySchema = AdminAccountSchema.merge(z.object({
    role: z.string().optional().nullable()
}));

export type AdminAccountListEntry = z.infer<typeof AdminAccountListEntrySchema>;

export const LoginAccountSchema = WalletAccountSchema.pick({
    currency: true,
    language: true,
    country: true,
    balanceCash: true,
    balanceFreebet: true,
    cookie: true,
    accessLevel: true,
    defaultCustomer: true,
    realityCheckIntervalSecs: true
}).merge(AdminAccountSchema.pick({
    permissions: true
})).merge(z.object({
    id: z.number(),
    userid: z.string(),
}));

export type LoginAccount = z.infer<typeof LoginAccountSchema>;

export const AccountToCreateSchema = AdminAccountSchema.omit({
    id: true,
    creationDate: true,
    loggedIn: true,
    sessionExpiry: true,
    sessionStartTime: true,
}).merge(z.object({
    newPassword: z.string().optional(),
    oldPassword: z.string().optional()
}));
export type AccountToCreate = z.infer<typeof AccountToCreateSchema>;

export const AccountToUpdateSchema = AccountToCreateSchema
    .omit({password: true})
    .merge(AdminAccountSchema
        .pick({id: true}))
    .merge(z.object({
        wallet: WalletAccountSchema.omit({
            id: true,
            userId: true,
            cookie: true
        }).partial({
            defaultCustomer: true,
            email: true
        })
    }));

export type AccountToUpdate = z.infer<typeof AccountToUpdateSchema>;