import {FetchBaseQueryError} from "@reduxjs/toolkit/query";

export function isFetchBaseQueryError(error: unknown): error is FetchBaseQueryError {
    const fbqError = error as FetchBaseQueryError;
    const fbqStatuses = ["FETCH_ERROR", "PARSING_ERROR", "CUSTOM_ERROR"];
    return typeof fbqError.status === "number" || fbqStatuses.includes(fbqError.status);
}

export type FetchBaseQueryHTTPError = {
    /**
     * * `number`:
     *   HTTP status code
     */
    status: number
    data: unknown
};
export function isFetchBaseQueryHTTPError(error: unknown):  error is FetchBaseQueryHTTPError{
    return typeof (error as FetchBaseQueryError).status === "number";
}
export type FetchBaseQueryFetchError = {
    /**
     * * `"FETCH_ERROR"`:
     *   An error that occured during execution of `fetch` or the `fetchFn` callback option
     **/
    status: 'FETCH_ERROR'
    data?: undefined
    error: string
};
export function isFetchBaseQueryFetchError(error: unknown): error is FetchBaseQueryFetchError {
    return (error as FetchBaseQueryError).status === "FETCH_ERROR";
}
export type FetchBaseQueryParsingError = {
    /**
     * * `"PARSING_ERROR"`:
     *   An error happened during parsing.
     *   Most likely a non-JSON-response was returned with the default `responseHandler` "JSON",
     *   or an error occured while executing a custom `responseHandler`.
     **/
    status: 'PARSING_ERROR'
    originalStatus: number
    data: string
    error: string
};
export function isFetchBaseQueryParsingError(error: unknown): error is FetchBaseQueryParsingError {
    return (error as FetchBaseQueryError).status === "PARSING_ERROR";
}
export type FetchBaseQueryCustomError = {
    /**
     * * `"CUSTOM_ERROR"`:
     *   A custom error type that you can return from your `fetchFn` where another error might not make sense.
     **/
    status: 'CUSTOM_ERROR'
    data?: unknown
    error: string
};
export function isFetchBaseQueryCustomError(error: unknown): error is FetchBaseQueryCustomError {
    return (error as FetchBaseQueryError).status === "CUSTOM_ERROR";
}