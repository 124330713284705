import { createTheme} from '@mui/material';

export const theme = createTheme({
    palette: {
        mode: "dark",
        primary: {
            main: "#4196d2",
        },
        secondary: {
            main: "#ffcb08",
        },
        text: {
            primary: "#e0e0e0"
        }
    },
    components: {
        MuiSelect: {
            styleOverrides: {
                select: {
                    minWidth: "8em",
                }
            }
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundImage: "linear-gradient(#4495D1, #44C8F5)",
                }
            }
        }
    }
});
