import {Box, BoxProps, styled, Tab, Tabs, TabsProps} from "@mui/material";
import {FunctionComponent, ReactChild, ReactElement, SyntheticEvent, useEffect, useState} from "react";
import {TabPanel} from "./TabPanel";

export type TabDefinition = {
    /**
     * Label for the tab
     */
    label: string;
    /**
     * Optional icon to show on the tab
     */
    icon?: ReactElement;
    /**
     * Contents for the tab
     */
    content: ReactChild;
}
export type TabSetProps = {
    /**
     * Defines the tabs in the {@link TabSet}
     */
    tabs: TabDefinition[];
    /**
     * Props to apply to the {@link Tabs} component used in the {@link TabSet}
     */
    tabsProps?: Omit<TabsProps, "value" | "onChange">;
    /**
     * Default tab index to display.
     *
     * Changing this will cause that tab to be focused
     */
    defaultTabIndex?: number;
    /**
     * Provide a function to render a tab's contents
     *
     * Default:
     * ```ts
     * <TabPanel
     *   key={index}
     *   tabIndex={index}
     *   currentTabIndex={currentIndex}
     *   sx={{
     *     position: "relative",
     *     height: "100%"
     *   }}
     * >
     *   {tab.content}
     * </TabPanel>
     * ```
     */
    renderTabContents?: (tab: TabDefinition, tabIndex: number, currentIndex: number) => ReactChild;
    /**
     * Provide a function to render a tab (the clickable tab part)
     *
     * Default:
     * ```ts
     * <Tab
     *   icon={tab.icon}
     *   iconPosition={tab.icon ? "start" : undefined}
     *   label={tab.label}
     *   key={index}
     * />
     * ```
     */
    renderTab?: (tab: TabDefinition, tabIndex: number) => ReactChild;
    /**
     * Override the component that wraps the tab buttons
     */
    TabBarComponent?: typeof Box;
    /**
     * Override root component for the {@link TabSet}
     */
    TabsContainerComponent?: typeof Box;
} & BoxProps;

const defaultRenderTabContents = (tab: TabDefinition, index: number, currentIndex: number): ReactChild => (
    <TabPanel
        key={index}
        tabIndex={index}
        currentTabIndex={currentIndex}
        sx={{
            position: "relative",
            height: "100%"
        }}
    >
        {tab.content}
    </TabPanel>
);
const defaultRenderTab = (tab: TabDefinition, index: number): ReactChild => (
    <Tab
        icon={tab.icon}
        iconPosition={tab.icon ? "start" : undefined}
        label={tab.label}
        key={index}
    />
)
const DefaultTabsContainer = styled(Box)({
    display: "flex",
    flexDirection: "column",
})
const DefaultTabBar = (props: BoxProps) => (
    <Box
        sx={{
            borderBottom: 1,
            borderColor: "divider",
            flex: "0 0 auto",
        }}
        {...props}
    />
)
/**
 * Convenience wrapper to simplify usage of MUI's {@link Tabs} & {@link Tab} components
 */
export const TabSet: FunctionComponent<TabSetProps> = ({
                                                           TabsContainerComponent = DefaultTabsContainer,
                                                           TabBarComponent = DefaultTabBar,
                                                           tabs,
                                                           tabsProps,
                                                           renderTab = defaultRenderTab,
                                                           renderTabContents = defaultRenderTabContents,
                                                           defaultTabIndex,
                                                           ...props
                                                       }) => {
    const [currentTabIndex, setCurrentTabIndex] = useState(defaultTabIndex ?? 0);
    useEffect(() => {
        defaultTabIndex !== undefined && setCurrentTabIndex(defaultTabIndex)
    }, [defaultTabIndex]);
    const handleChange = (event: SyntheticEvent, newTabIndex: number) => {
        setCurrentTabIndex(newTabIndex);
    };
    return (
        <TabsContainerComponent {...props}>
            <TabBarComponent>
                <Tabs
                    value={currentTabIndex}
                    onChange={handleChange}
                    {...tabsProps}
                >
                    {tabs.map((tab, index) =>
                        renderTab(tab, index)
                    )}
                </Tabs>
            </TabBarComponent>
            {tabs.map((tab, index) =>
                renderTabContents(tab, index, currentTabIndex)
            )}
        </TabsContainerComponent>
    )
}
