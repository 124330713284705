import {FunctionComponent, ReactChild} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {Alert, AlertTitle, Paper} from '@mui/material';
import {Button} from "@mui/material";

const ErrorPage: FunctionComponent<{ title: string, message: ReactChild, action?: VoidFunction, actionLabel?: string }> = ({
                                                                                                                               title,
                                                                                                                               message,
                                                                                                                               action,
                                                                                                                               actionLabel
                                                                                                                           }) => {
    return (
        <Paper sx={{padding: 1}}>
            <Alert
                severity="error"
                action={action && actionLabel ?
                    <Button color="inherit" size="small" onClick={action}>
                        {actionLabel}
                    </Button>
                    : undefined
                }>
                <AlertTitle>{title}</AlertTitle>
                {message}
            </Alert>
        </Paper>
    )
}

export const Error403Page: FunctionComponent<{location: ReturnType<typeof useLocation>}> = () => {
    const navigate = useNavigate();
    const goBack = () => navigate(-1);
    return <ErrorPage
        title="403 - Forbidden"
        message="You do not have access to this page."
        action={goBack}
        actionLabel="Back"
    />
}

export const Error404Page: FunctionComponent = () => {
    const navigate = useNavigate();
    const goBack = () => navigate(-1,);
    return <ErrorPage
        title="404 - Not Found"
        message="This page does not exist!"
        action={goBack}
        actionLabel="Back"
    />
}
