import {Accordion, AccordionDetails, AccordionSummary, FormGroup, Paper, styled, Typography} from "@mui/material";
import {theme} from "../../theme";
import {FunctionComponent} from "react";
import {LobbyEnvironmentOptions} from "../inputs/environment-selector/LobbyEnvironmentSelect";
import {useAppSelector} from "../../store/hooks/root-hooks";
import {selectLobbyEnvironmentIsValid} from "../../store/slices/lobbySlice";
import {LaunchOptionsSelector} from "./LaunchOptions";
import {ExpandMore} from "@mui/icons-material";
import {useBreakpoints} from '../../hooks/use-breakpoints';

const OptionsBarContainer = styled(Paper)(({theme}) =>({
    padding: theme.spacing(1),
    paddingTop: theme.spacing(2),
    marginBottom: theme.spacing(0.5),
    marginTop: theme.spacing(-1),
}));

const OptionsBar = styled(FormGroup)({
    marginBottom: theme.spacing(1),
    "& > *": {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    "& > * > *": {
        marginLeft: theme.spacing(0.5),
        marginRight: theme.spacing(0.5),
    }
});

export const LobbyOptions: FunctionComponent = () => {
    const validEnvironment = useAppSelector(selectLobbyEnvironmentIsValid);
    const {isNarrow} = useBreakpoints();
    return (
        <OptionsBarContainer elevation={2}>
            <LobbyEnvironmentOptions />
            {validEnvironment && (
                <>
                    <Accordion defaultExpanded={!isNarrow}>
                        <AccordionSummary expandIcon={<ExpandMore/>}>
                            <Typography>
                                Launch Options
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <OptionsBar row>
                                <LaunchOptionsSelector/>
                            </OptionsBar>
                        </AccordionDetails>
                    </Accordion>
                    {/*
                    <OptionsBar row sx={{marginTop: 1}}>
                        <GamesListViewOptions/>
                    </OptionsBar>
                    */}
                </>
            )}
        </OptionsBarContainer>
    )
}
