import {createAsyncThunk, createSelector, createSlice} from "@reduxjs/toolkit";
import {RootState} from "../store";
import {z} from "zod";

export const fetchConfig = createAsyncThunk(
    "config/fetchConfig",
    async () => {
        return fetch("./lobby-config.json")
            .then((res) => res.json())
    }
)

export const GameButtonConfigSchema = z.object({
    width: z.number(),
    height: z.number(),
    hoverOverlays: z.boolean().optional(),
});

export const APIConfigSchema = z.object({
    root: z.string(),
});

export const TabsConfigSchema = z.object({
    newTabDays: z.number(),
    showAllGamesTab: z.boolean(),
    showFavouritesAsTab: z.boolean(),
    systemTabNamesOverride: z.object({
        newGamesTabLabel: z.string(),
        allGamesTabLabel: z.string(),
        otherGamesTabLabel: z.string(),
        favouriteGamesTabLabel: z.string(),
    }).partial(),
    // TODO: tab theming?
}).partial();

export const LobbyFeaturesConfigSchema = z.object({
    admin: z.boolean(),
    devOptions: z.boolean(),
    favourites: z.boolean(),
    gameSets: z.boolean(),
    userMenu: z.boolean(),
    accountEmail: z.boolean(),
    tabs: TabsConfigSchema.or(z.literal(false)),
}).partial();

export const ShowModeConfigSchema = z.object({
    lockedCustomer: z.string(),
    lockedServer: z.string(),
    noLogOut: z.boolean(),
}).partial();

export const LobbyConfigSchema = z.object({
    title: z.string(),
    assetsRoot: z.string(),
    gameButtonConfig: GameButtonConfigSchema,
    apiConfig: APIConfigSchema,
    lobbyFeaturesConfig: LobbyFeaturesConfigSchema,
    showModeConfig: ShowModeConfigSchema.optional(),
    // TODO: theme config
});

export type GameButtonConfig = z.infer<typeof GameButtonConfigSchema>;
export type APIConfig = z.infer<typeof APIConfigSchema>;
export type TabsConfig = z.infer<typeof TabsConfigSchema>;
export type LobbyFeaturesConfig = z.infer<typeof LobbyFeaturesConfigSchema>;
export type ShowModeConfig = z.infer<typeof ShowModeConfigSchema>;
export type LobbyConfig = z.infer<typeof LobbyConfigSchema>;


interface ConfigStateLoaded {
    config: LobbyConfig
    loaded: true;
}

interface ConfigStateUnloaded {
    config: undefined;
    loaded: false;
}

type ConfigState = ConfigStateLoaded | ConfigStateUnloaded;

const initialState: ConfigState = {
    config: undefined,
    loaded: false,
}

export const configSlice = createSlice({
    name: "config",
    initialState: initialState as ConfigState,
    reducers: {},
    extraReducers: (builder) => builder
        .addCase(fetchConfig.fulfilled, (state, action) => {
            state.config = LobbyConfigSchema.parse(action.payload);
            state.loaded = true;
        })
});

export const selectLobbyConfig = (state: RootState): LobbyConfig | undefined => state.config.config;
export const selectConfigLobbyTitle = (state: RootState): string => state.config.config?.title ?? "Lobby";
export const selectConfigAssetsRoot = (state: RootState): string => state.config.config?.assetsRoot ?? `${window.location.origin}/`;
export const selectAPIConfig = (state: RootState): APIConfig | undefined => state.config.config?.apiConfig;
export const selectAPIRootUrl = createSelector(selectAPIConfig, (apiConfig) => apiConfig?.root ?? "");
export const selectLobbyFeaturesConfig = (state: RootState): LobbyFeaturesConfig => state.config.config?.lobbyFeaturesConfig ?? {};
export const selectLobbyFeaturesEnabled = createSelector(selectLobbyFeaturesConfig, (config: Partial<LobbyFeaturesConfig> = {}) => {
    const {
        admin = true,
        devOptions = false,
        favourites = false,
        gameSets = true,
        userMenu = true,
        tabs = false,
        accountEmail = false,
    } = config;
    return {
        admin,
        devOptions,
        favourites,
        gameSets,
        userMenu,
        tabs: !!tabs,
        accountEmail,
    }
});
export const selectConfigFavouritesEnabled = createSelector(selectLobbyFeaturesEnabled, (features) => features.favourites);
export const selectConfigAdminEnabled = createSelector(selectLobbyFeaturesEnabled, (features) => features.admin);
export const selectConfigGameSetsEnabled = createSelector(selectLobbyFeaturesEnabled, (features) => features.gameSets);
export const selectConfigDevOptionsEnabled = createSelector(selectLobbyFeaturesEnabled, (features) => features.devOptions);
export const selectConfigTabsEnabled = createSelector(selectLobbyFeaturesEnabled, (features) => features.tabs);
export const selectConfigAccountEmailEnabled = createSelector(selectLobbyFeaturesEnabled, (features) => features.accountEmail);
export const selectGameTabsConfig = (state: RootState): TabsConfig | undefined => state.config.config?.lobbyFeaturesConfig.tabs || undefined;
export const selectGameButtonConfig = (state: RootState): GameButtonConfig | undefined => state.config.config?.gameButtonConfig;
export const selectGameButtonSize = createSelector(selectGameButtonConfig, (config) => ({
    width: config?.width || 100,
    height: config?.height || 100,
}));
export const selectShowModeConfig = (state: RootState): ShowModeConfig | undefined => state.config.config?.showModeConfig;
export const selectShowModeEnabled = createSelector(selectShowModeConfig, (config) => !!config);
export const selectConfigNoLogOutButton = createSelector(selectShowModeConfig, (config) => config?.noLogOut);
export const selectConfigLockedToServer = createSelector(selectShowModeConfig, (config) => config?.lockedCustomer);
export const selectConfigLockedToCustomer = createSelector(selectShowModeConfig, (config) => config?.lockedServer);


