import {FunctionComponent, useEffect} from "react";
import {fetchConfig, LobbyConfig, selectLobbyConfig} from "../store/slices/configSlice";
import {useAppDispatch, useAppSelector} from "../store/hooks/root-hooks";
import {LinearProgress} from "@mui/material";
import {Helmet} from 'react-helmet';

export interface ConfigLoaderProps {
    // set children to a render function
    children: (config: LobbyConfig) => JSX.Element;
}
export const ConfigLoader: FunctionComponent<ConfigLoaderProps> = ({children}) => {
    const dispatch = useAppDispatch();
    const config = useAppSelector(selectLobbyConfig);
    useEffect(() => {
        dispatch(fetchConfig());
    }, [dispatch]);
    // use a render prop, using
    return config ? (
        <>
            <Helmet>
                <title>{config.title}</title>
            </Helmet>
            {children(config)}
        </>
    ) : <LinearProgress />;
}
