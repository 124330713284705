import {FunctionComponent, ReactElement} from "react";
import {ListItemLink} from "./ListItemLink";

export interface NavDrawerItemProps {
    /**
     * Route that this item links to
     */
    to: string;
    /**
     * Optional icon to display
     */
    icon?: ReactElement;
    /**
     * Label displayed for the Item
     */
    label: string;

    onClick? : () => void;
}

/**
 * Type-guard for the NavDrawerItemProps
 */
export function isNavDrawerItemProps(obj: unknown): obj is NavDrawerItemProps {
    const drawerItem = obj as Partial<NavDrawerItemProps>;
    return (typeof drawerItem.to === "string" && typeof drawerItem.label === "string");
}

/**
 * Item to render in {@link NavDrawer}
 *
 * You probably won't need to use this directly
 */
export const NavDrawerItem: FunctionComponent<NavDrawerItemProps> = ({
                                                                         to,
                                                                         icon,
                                                                         label,
    onClick
                                                                     }) => (
    <ListItemLink
        primary={label}
        to={to}
        icon={icon}
        onClick={onClick}

    />
)