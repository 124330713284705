import React, {FunctionComponent, useMemo, useState} from "react";
import {
    Button, Divider,
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel, OutlinedInput,
    Snackbar, styled,
} from "@mui/material";
import {FileCopyOutlined, Logout, Refresh, Save} from "@mui/icons-material";
import {Field, Formik} from "formik";
import {TextField} from "formik-mui";
import {EditableAccount, EditableAccountSchema} from "./user-menu-types";
import {useAppSelector} from "../../store/hooks/root-hooks";
import {selectCurrentUserToken} from "../../store/slices/accountSlice";
import {toFormikValidationSchema} from "zod-formik-adapter";
import {NumberField} from '../common/inputs/formik/NumberField';
import {useGetCurrenciesQuery, useGetLanguagesQuery} from '../../store/apis/lobby-api';
import {AutocompleteField} from '../common/inputs/formik/AutocompleteField';

interface UserMenuFormBaseProps {
    onRefreshDataClicked: () => void;
    onLogOutClicked: () => void;
    account: EditableAccount;
    isBusy: boolean;
}

interface UserMenuFormViewProps {
    isEditable: false;
    validateForm?: undefined;
    onSubmit?: undefined;
}

interface UserMenuFormEditProps {
    isEditable: true;
    onSubmit: (values: EditableAccount) => void;
}

const ColumnForm = styled("form")(({theme}) => ({
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
    margin: theme.spacing(1),
    marginTop: theme.spacing(2),
}))

export type UserMenuFormProps = UserMenuFormBaseProps & (UserMenuFormViewProps | UserMenuFormEditProps);

export const UserMenuForm: FunctionComponent<UserMenuFormProps> = ({
                                                                       account,
                                                                       onRefreshDataClicked,
                                                                       isEditable,
                                                                       isBusy,
                                                                       onSubmit = () => undefined,
                                                                       onLogOutClicked,
                                                                   }) => {
    const token = useAppSelector(selectCurrentUserToken);
    const {data: currenciesData} = useGetCurrenciesQuery();
    const {data: languagesData} = useGetLanguagesQuery();
    const currencies = useMemo(() => currenciesData?.map((v) => v.value), [currenciesData]);
    const languages = useMemo(() => languagesData?.map((v) => v.value), [languagesData]);
    const [showingCopySnackbar, setShowingCopySnackbar] = useState(false);
    const onCopySnackbarClosed = () => {
        setShowingCopySnackbar(false);
    }
    const onCopyTokenClicked = () => {
        navigator.clipboard.writeText(token)
            .then(() => {
                setShowingCopySnackbar(true);
            })
    }
    return (
        <>
            <Formik
                validationSchema={toFormikValidationSchema(EditableAccountSchema)}
                initialValues={account}
                onSubmit={onSubmit}
                onReset={onRefreshDataClicked}
                enableReinitialize={true}
            >
                {({submitForm, handleSubmit, handleReset, isSubmitting, dirty, isValid}) =>
                    <ColumnForm onSubmit={handleSubmit}>
                        <Button
                            onClick={handleReset}
                            color="secondary"
                            variant="outlined"
                            startIcon={<Refresh/>}
                        >
                            Refresh
                        </Button>
                        <Divider />
                        <FormControl>
                            <InputLabel htmlFor="token-display">
                                Token
                            </InputLabel>
                            <OutlinedInput
                                id="token-display"
                                type="text"
                                value={token}
                                inputProps={{readOnly: true}}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={onCopyTokenClicked}
                                            size="large"
                                        >
                                            <FileCopyOutlined/>
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                        <Divider />
                        <NumberField
                            name="balanceCash"
                            label="Balance"
                            disabled={!isEditable}
                        />
                        <NumberField
                            name="balanceFreebet"
                            label="Free Bets"
                            disabled={!isEditable}
                        />
                        <AutocompleteField
                            options={languages}
                            name="language"
                            label="Language"
                            disableClearable
                            sortOptions
                            freeSolo
                            disabled={!isEditable}
                        />
                        <AutocompleteField
                            options={currencies}
                            name="currency"
                            label="Currency"
                            disableClearable
                            sortOptions
                            freeSolo
                            disabled={!isEditable}
                        />
                        <Field
                            component={TextField}
                            name="country"
                            label="Country"
                            disabled={!isEditable}
                        />
                        <NumberField
                            name="realityCheckIntervalSecs"
                            label="RC Interval (Secs)"
                            disabled={!isEditable}
                            treatZeroAsNull
                        />
                        {isEditable && (
                            <Button
                                type="submit"
                                variant="contained"
                                disabled={isSubmitting || isBusy || !dirty || !isValid}
                                onClick={submitForm}
                                color="primary"
                                size="large"
                                startIcon={<Save/>}
                            >
                                Save Changes
                            </Button>
                        )}
                        <Divider />
                        <Button
                            onClick={onLogOutClicked}
                            color="secondary"
                            variant="contained"
                            startIcon={<Logout />}
                        >
                            Log Out
                        </Button>
                    </ColumnForm>
                }
            </Formik>
            <Snackbar
                open={showingCopySnackbar}
                autoHideDuration={2000}
                onClose={onCopySnackbarClosed}
                message="Token copied to clipboard!"
            />
        </>
    );
}
