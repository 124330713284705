import React, {ChangeEvent, FunctionComponent, SyntheticEvent} from "react";
import {
    Checkbox,
    FormControlLabel,
    FormGroup,
    SelectChangeEvent, Tooltip, useTheme
} from '@mui/material';
import {
    changeAffiliate, changeDevFlag,
    changeLanguageOverride,
    changeLaunchMode,
    changePlatform,
    changeRealPlay,
    changeServerType,
    GameLaunchMode,
    selectGameLaunchOptions, selectLobbyEnvironmentOptions
} from "../../store/slices/lobbySlice";
import {useAppDispatch, useAppSelector} from "../../store/hooks/root-hooks";
import {defaultServerTypeForServer, platforms, serverTypes} from "../../constants/constants";
import {LobbySelect} from "../inputs/LobbySelect";
import {AffiliateSelect} from "../inputs/AffiliateSelect";
import {LanguageSelect} from "../inputs/LanguageSelect";
import {useBreakpoints} from '../../hooks/use-breakpoints';

export const LaunchOptionsSelector: FunctionComponent = () => {
    const dispatch = useAppDispatch();
    const environmentOptions = useAppSelector(selectLobbyEnvironmentOptions);
    const {
        platform,
        serverType,
        realPlay,
        launchMode,
        languageOverride,
        devFlag
    } = useAppSelector(selectGameLaunchOptions);
    const defaultServerType = defaultServerTypeForServer(environmentOptions.server);
    const onPlatformChanged = (e: SelectChangeEvent) => {
        dispatch(changePlatform(e.target.value))
    };
    const onAffiliateChanged = (e: SyntheticEvent, value: string | null) => {
        if (value) {
            dispatch(changeAffiliate(value));
        }
    };
    const onLaunchModeChanged = (e: SelectChangeEvent) => {
        dispatch(changeLaunchMode(e.target.value as GameLaunchMode));
    };
    const onServerTypeChanged = (e: SelectChangeEvent) => {
        dispatch(changeServerType(e.target.value));
    };
    const onRealPlayChanged = (e: ChangeEvent<HTMLInputElement>) => {
        dispatch(changeRealPlay(e.target.checked));
    };
    const onLanguageChanged = (e: SyntheticEvent, value: string | null) => {
        dispatch(changeLanguageOverride(value));
    };
    const onDevFlagChanged = (e: ChangeEvent<HTMLInputElement>) => {
        dispatch(changeDevFlag(e.target.checked));
    };

    const {isNarrow} = useBreakpoints();
    const theme = useTheme();
    return (
        <FormGroup row={!isNarrow}>
            <FormGroup row={isNarrow} sx={!isNarrow ? {position: "relative", top: theme.spacing(-2)} : undefined}>
                <FormControlLabel label="Real Play"
                                  control={
                                      <Checkbox name="realPlay"
                                                checked={realPlay}
                                                onChange={onRealPlayChanged}
                                      />
                                  }
                />
                <Tooltip title="Add extra logging flags/v3 dev flag">
                    <FormControlLabel label="Dev Flag"
                                      control={
                                          <Checkbox name="devFlag"
                                                    checked={devFlag}
                                                    onChange={onDevFlagChanged}
                                          />
                                      }
                    />
                </Tooltip>
            </FormGroup>
            <LobbySelect id="launch-options-platform"
                         label="Platform"
                         name="platform"
                         value={platform ?? "desktop"}
                         values={platforms}
                         onChange={onPlatformChanged}
                         sx={{minWidth: "7em"}}
            />
            <LobbySelect id="launch-options-server-type"
                         label="Server Type"
                         name="server-type"
                         value={serverType ?? defaultServerType}
                         values={serverTypes}
                         onChange={onServerTypeChanged}
                         sx={{minWidth: "6em"}}
            />
            <LobbySelect id="launch-options-launch-mode"
                         label="Launch Mode"
                         name="launch-mode"
                         value={launchMode ?? "new"}
                         values={[
                             {label: "In this window", value: "self"},
                             {label: "In an iframe", value: "iframe"},
                             {label: "In a new tab", value: "new"},
                         ]}
                         onChange={onLaunchModeChanged}
            />
            <AffiliateSelect id="launch-option-affiliate"
                             onChange={onAffiliateChanged}
            />
            <Tooltip title="Override account default language">
                <LanguageSelect id={"launch-option-language"}
                                onChange={onLanguageChanged}
                                value={languageOverride ?? null}
                                sx={{minWidth: "8em"}}
                />
            </Tooltip>
        </FormGroup>
    )
}
