import React, {ChangeEvent, Fragment, FunctionComponent, useState} from "react";
import {Button, TextField} from "@mui/material";

export interface IFrameSizeButtonProps {
    gameRef : React.RefObject<HTMLIFrameElement>;
    initialSize: {height: string, width: string}
}

export const IFrameSizeButton : FunctionComponent<IFrameSizeButtonProps> = (props) =>{
    const [width, setWidth] = useState(props.initialSize.width);
    const [height, setHeight] = useState(props.initialSize.height);
    const {gameRef} = props
    const widthChange = function (e : ChangeEvent<HTMLInputElement>){
        setWidth(e.target.value);
    }

    const heightChange = function (e : ChangeEvent<HTMLInputElement>){
        setHeight(e.target.value);
    }

    const setSize = function (){
        //[^0-9\.] checks to see if there's only numbers (so 100 would match, 100px, or 100% wouldn't). If it's only numbers, add "px" so it's a valid css prop
        const w = width.match(/[^0-9.]+/gm) ? width : `${width}px`
        const h = height.match(/[^0-9.]+/gm) ? height : `${height}px`
        if (gameRef.current) {
            setWidth(w);
            setHeight(h);
            gameRef.current.style.width = w;
            gameRef.current.style.height = h;
        }
    }

    return <Fragment>
        <TextField value={width} type={"string"} onChange={widthChange} label={"Width"}/>
        <TextField value={height} type={"string"} onChange={heightChange} label={"Height"}/>
        <Button onClick={setSize}>Set Size</Button>
    </Fragment>
}
