import React, {FunctionComponent, useMemo} from "react";
import {FormControl, FormControlProps, InputLabel, MenuItem, Select, SelectChangeEvent} from "@mui/material";

export interface LobbySelectProps {
    id: string;
    label: string;
    name: string;
    value: string;
    values: string[] | Array<{ value: string, label: string }>;
    onChange: (e: SelectChangeEvent) => void;
    sx?: FormControlProps["sx"];
}

export const LobbySelect: FunctionComponent<LobbySelectProps> = ({id, label, name, value, values, onChange, sx}) => {
    const items = useMemo(() => values.map((val) =>
        typeof val === "string" ? {label: val, value: val} : val
    ), [values]);
    const labelId = `${id}-label`;
    return (
        <FormControl
            sx={{
                marginRight: 0.5,
                marginLeft: 0.5,
                ...sx
            }}
        >
            <InputLabel
                shrink
                id={labelId}
            >
                {label}
            </InputLabel>
            <Select
                id={id}
                name={name}
                label={label}
                labelId={labelId}
                value={value}
                onChange={onChange}
            >
                {items.map(({label, value}) => (
                    <MenuItem
                        key={value}
                        value={value}
                    >
                        {label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}
