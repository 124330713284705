import React, {FunctionComponent, useMemo} from 'react';
import ExpandedGame from '../models/expanded-game';
import {Box} from '@mui/material';
import GameButton from './GameButton';
import {useAppDispatch, useAppSelector} from "../store/hooks/root-hooks";
import {selectLobbyGamesSearchString, toggleGameFavourite} from "../store/slices/lobbySlice";
import {selectGameButtonSize} from "../store/slices/configSlice";
import {VirtualizedGrid} from './common/VirtualizedGrid';
import useMeasure from 'react-use-measure';

export interface GamesListProps {
    games: ExpandedGame[];
    scrollPadding?: number
}


export const GamesList: FunctionComponent<GamesListProps> = ({games, scrollPadding = 10}) => {
    const dispatch = useAppDispatch();
    const {width: buttonWidth, height: buttonHeight} = useAppSelector(selectGameButtonSize);
    const [sizeRef, bounds] = useMeasure();
    const cols = useMemo(() =>
            Math.max(
                Math.floor((bounds.width - scrollPadding) / buttonWidth),
                1
            ) || 5,
        [bounds, scrollPadding, buttonWidth]);

    const search = useAppSelector(selectLobbyGamesSearchString);
    const gamesMatchingSearch = useMemo(() => games
        .filter((game) => {
            if (search) {
                return game.name.toLowerCase().includes(search.toLowerCase())
                    || game.displayName.toLowerCase().includes(search.toLowerCase());
            }
            return true;
        }), [games, search])

    const onFavouriteClicked = (game: ExpandedGame) => dispatch(toggleGameFavourite(game.id));

    return (
        <Box
            sx={{
                position: "relative",
                height: "100%",
                flex: "1 1 auto",
            }}
            ref={sizeRef}
        >
            <VirtualizedGrid
                items={gamesMatchingSearch}
                cols={cols}
                renderItem={(game) => (
                    <GameButton
                        game={game}
                        onFavouriteClick={onFavouriteClicked}
                        width={buttonWidth}
                        height={buttonHeight}
                    />
                )}
            />
        </Box>
    )
}
