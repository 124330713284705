import React, {Fragment, FunctionComponent, useEffect, useRef} from "react";
import {Button, Paper, styled, FormGroup, Divider} from "@mui/material";
import {theme} from "../theme";
import {useAppSelector} from "../store/hooks/root-hooks";
import {selectLaunchUrlForGameById} from "../store/misc-selectors";
import {useParams} from "react-router-dom";
import {PostMessageButton} from "../components/PostMessageButton";
import {IFrameSizeButton} from "../components/IFrameResizeButton";

const OptionsBarContainer = styled(Paper)({
    padding: "1em",
    paddingTop: "2em",
    marginBottom: "0.5em",
    marginTop: "-1em",
});

const OptionsBar = styled(FormGroup)({
    "& > *": {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    "& > * > *": {
        marginLeft: theme.spacing(0.5),
        marginRight: theme.spacing(0.5),
    }
});

const VisibleDivider = styled(Divider)({
    borderWidth: "1px",
    borderColor: "white"
});




export const GamePage: FunctionComponent = () => {
    const gameRef = useRef<HTMLIFrameElement>(null);
    const search = "?" + window.location.href.replace(/.+\?/gm, "")
    const urlParams = new URLSearchParams(search)
    const size = {height: (urlParams.get('height') ?? "85%"), width: (urlParams.get('width') ?? "100%")}
    const {id} = useParams<"id">();

    useEffect(() => {
        const elem = document.getElementsByClassName("MuiContainer-root")[0] as HTMLElement;
        elem.style.maxWidth = "100%"

        return () => {
            elem.style.maxWidth = "";
        }
    }, []);

    console.log(document.getElementById("root"));
    if (id === undefined) {
        throw new Error("Missing game id in path!")
    }
    const gameUrl = useAppSelector(selectLaunchUrlForGameById(+id));

    const stopAutoplay = () => {
        gameRef.current?.contentWindow?.postMessage({type: "stopAutoplay"}, "*");
    }

    const disableGameInput = () => {
        gameRef.current?.contentWindow?.postMessage({type: "disableGameInput"}, "*");
    }

    const updateBalance = () => {
        gameRef.current?.contentWindow?.postMessage({type: "updateBalance", balance: 10.1, freeBets: 0}, "*");
    }

    const enableGameInput = () => {
        gameRef.current?.contentWindow?.postMessage({type: "enableGameInput"}, "*");
    }

    return <Fragment>
        <OptionsBarContainer>
            <OptionsBar row>
                <PostMessageButton gameRef={gameRef}/>
                <VisibleDivider variant="middle"/>
                <IFrameSizeButton initialSize={size} gameRef={gameRef}/>
                <VisibleDivider variant="middle"/>
                <Button onClick={updateBalance}>Update Balance</Button>
                <Button onClick={disableGameInput}>Disable Game Input</Button>
                <Button onClick={enableGameInput}>Enable Game Input</Button>
                <Button onClick={stopAutoplay}>Stop Autoplay</Button>
            </OptionsBar>

        </OptionsBarContainer>
        {<iframe className={"game-container"} title={"Game-Container"} src={gameUrl} ref={gameRef} style={size}/>}
    </Fragment>
}
