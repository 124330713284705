import {z} from "zod";

/*export const PermissionsSchema = z.object({
    adminUsers: z.boolean(),
    adminSelfExclusion: z.boolean(),
    adminGames: z.boolean(),
    superAdmin: z.boolean(),
    adminSelf: z.boolean(),
    viewAllGames: z.boolean(),
});*/

export const PermissionsSchema = z.enum([
    "EditSelf",
    "AllowedDemoPlay",
    "AllowedDevFlag",
    "ViewAllGames",
    "AdminView",
    "AdminUsers",
    "AdminGames",
    "AdminGameSets",
    "AdminTabs",
    "AdminExclusions",
    "AdminPermissions"
]).array();

export type Permissions = z.infer<typeof PermissionsSchema>;