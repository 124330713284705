import {createSelector, createSlice, isAnyOf} from "@reduxjs/toolkit";
import {LoginAccount} from "../../models/account";
import {RootState} from "../store";
import {AdminPageName, LobbyPermissions} from "../../constants/lobby-permissions";
import {lobbyApi} from "../apis/lobby-api";
import {apiError} from '../middleware/api-error-handling-middleware';
import {PermissionsSchema} from "../../models/permissions";

export type LogoutReason = "logout" | "expired" | "forbidden";

export interface AccountState {
    current: LoginAccount | undefined;
    logoutReason: LogoutReason | undefined;
}

const initialState: AccountState = {
    current: undefined,
    logoutReason: undefined,
}

export const accountSlice = createSlice({
    name: "account",
    initialState: initialState as AccountState,
    reducers: {},
    extraReducers: (builder) => builder
        .addMatcher(
            isAnyOf(
                lobbyApi.endpoints.login.matchFulfilled,
                lobbyApi.endpoints.checkLogin.matchFulfilled,
            ),
            (state, {payload}) => {
                state.current = payload;
                state.logoutReason = undefined;
            })
        .addMatcher(lobbyApi.endpoints.logout.matchFulfilled,
            (state, {payload}) => {
                if (state.current) {
                    state.current = undefined;
                    state.logoutReason = "logout";
                }
            })
        .addMatcher(
            isAnyOf(
                apiError.match,
                lobbyApi.endpoints.checkLogin.matchRejected,
            ),
            ((state, {payload}) => {
                // whenever we get a 401 error from the admin API, log us out
                if (payload?.status === 401 && state.current) {
                    state.logoutReason = "expired";
                    state.current = undefined;
                }
            }))
});

export const checkPermissionsForUser = (user: LoginAccount | undefined, permission: LobbyPermissions) => {
    if (!user?.permissions) {
        return false;
    }
    switch (permission) {
        case LobbyPermissions.LIMITED_GAMES:
            return true;
        case LobbyPermissions.ALL_GAMES:
            return user.permissions.includes(PermissionsSchema.element.enum.ViewAllGames);
        case LobbyPermissions.SELF_ADMIN:
            return user.permissions.includes(PermissionsSchema.element.enum.EditSelf);
        case LobbyPermissions.ADMIN_GAMES:
            return user.permissions.includes(PermissionsSchema.element.enum.AdminGames);
        case LobbyPermissions.ADMIN_ALL_USERS_LIMITED:
            return user.permissions.includes(PermissionsSchema.element.enum.AdminUsers);
        case LobbyPermissions.ADMIN_ALL_USERS_FULL:
            return user.permissions.includes(PermissionsSchema.element.enum.AdminUsers);
        case LobbyPermissions.ADMIN_EXCLUSIONS:
            return user.permissions.includes(PermissionsSchema.element.enum.AdminExclusions);
        default:
            return false;
    }
}

// selectIsLoggedIn
// selectPermissionsForCurrentUser
// selectCurrentUserHasAdminPageAccess(page: AdminPageName)
export const selectCurrentUser = (state: RootState) => state.account.current;
export const selectCurrentUserOrError = (state: RootState) => {
    if (state.account.current) {
        return state.account.current;
    }
    throw new Error("Not logged in!")
}
export const selectCurrentUserHasPermissions = (permission: LobbyPermissions) =>
    createSelector(
        selectCurrentUser,
        (user) => {
            return checkPermissionsForUser(user, permission);

        });

export const selectCurrentUserHasAdminAccess = createSelector(
    selectCurrentUser,
    (user) =>
        checkPermissionsForUser(user, LobbyPermissions.ADMIN_ALL_USERS_LIMITED)
        || checkPermissionsForUser(user, LobbyPermissions.ADMIN_ALL_USERS_FULL)
        || checkPermissionsForUser(user, LobbyPermissions.ADMIN_GAMES)
        || checkPermissionsForUser(user, LobbyPermissions.ADMIN_EXCLUSIONS)
)
export const selectCurrentUserHasAdminPageAccess = (page: AdminPageName) => createSelector(
    selectCurrentUser,
    (user) => {
        switch (page) {
            case "games":
                return checkPermissionsForUser(user, LobbyPermissions.ADMIN_GAMES);
            case "users":
                return checkPermissionsForUser(user, LobbyPermissions.ADMIN_ALL_USERS_FULL)
                    || checkPermissionsForUser(user, LobbyPermissions.ADMIN_ALL_USERS_LIMITED);
            case "exclusions":
                return checkPermissionsForUser(user, LobbyPermissions.ADMIN_EXCLUSIONS)
                    || checkPermissionsForUser(user, LobbyPermissions.ADMIN_ALL_USERS_FULL)
                    || checkPermissionsForUser(user, LobbyPermissions.ADMIN_ALL_USERS_LIMITED);
            default:
                return false;
        }
    }
)
export const selectCurrentUserLanguage = (state: RootState) => state.account.current?.language

export const selectCurrentUserToken = createSelector(
    selectCurrentUser,
    (user?: Pick<LoginAccount, "cookie" | "userid">) =>
        user ? `${user.cookie};${user.userid}` : ""
)

export const selectLogoutReason = (state: RootState): LogoutReason | undefined => state.account.logoutReason;
