import {createAction, isRejectedWithValue, Middleware} from '@reduxjs/toolkit';
import {z} from 'zod';

const rtkQueryRejectedActionSchema = z.object({
    payload: z.object({
        status: z.number().or(z.string()),
        data: z.object({
            type: z.string(),
            title: z.string(),
            status: z.number().or(z.string()),
            traceId: z.string(),
        }).partial().or(z.string()).optional(),
    }),
    meta: z.object({
        arg: z.object({
            endpointName: z.string(),
        }),
        requestStatus: z.string(),
    })
});

export const apiError = createAction(
    "adminErrorHandlingMiddleware/apiError",
    (status: number | string, message: string, endpoint: string) => ({
        payload: {status, message, endpoint},
    })
)

/**
 * Middleware that will dispatch an action whenever the admin API has an action rejected (usually due to a
 * server error)
 *
 * This is primarily used to log the user out on a 401/403 error (see accountSlice's extraReducers)
 * @param api
 */
export const apiErrorHandlingMiddleware: Middleware = (api) => (next) => (action) => {
    if (isRejectedWithValue(action) && action.type.match(/(admin|lobby)Api\/execute(\w+)\/rejected/)) {
        const parseResult = rtkQueryRejectedActionSchema.safeParse(action);
        if (parseResult.success) {
            const {payload: {status, data}, meta: {arg: {endpointName}}} = parseResult.data;
            const title = typeof data === "string" ? data : data?.title ?? "Unknown error";
            api.dispatch(apiError(status, title, endpointName));
        } else {
            console.error("Failed to parse rejected api action:", parseResult.error)
        }
    }

    return next(action);
}
