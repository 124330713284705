import {createSelector, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../store";
import {lobbyApi} from '../apis/lobby-api';
import {
    defaultAffiliateForCustomerServer,
    defaultPlatform,
    defaultServerTypeForServer
} from "../../constants/constants";
import {LabelledValue} from "../../models/labelled-value";
import WithGameInfo from '../../models/expanded-game';
import {StoredGame, storedGameToGame} from '../../models/game';
import {Server} from "../../models/server";
import {Customer} from "../../models/customer";

export interface LobbyOptions {
    customers: Customer[];
    servers: Server[];
    affiliates: LabelledValue[];
    games: WithGameInfo<StoredGame>[];
    showLobbyAppBarOptions: boolean;
}

export type GameLaunchMode = "self" | "iframe" | "new";

export interface GameLaunchOptions {
    realPlay: boolean;
    platform: string;
    launchMode: GameLaunchMode;
    languageOverride: string | null;
    devFlag: boolean;
}

export interface PerEnvironmentLaunchOptions {
    serverType: string;
    affiliate: string;
}

export interface EnvironmentOptions {
    customer: string;
    server: string;
}

export interface GameListOptions {
    search?: string;
    sortAscending: boolean;
    favourites: number[];
}

export type LobbyState =
    Omit<LobbyOptions, "customers" | "affiliates">
    & Partial<GameLaunchOptions>
    & Partial<EnvironmentOptions>
    & Partial<PerEnvironmentLaunchOptions>
    & GameListOptions;

type PersistedLobbyState = Pick<LobbyState,
    "customer" | "server" | "realPlay" | "affiliate" | "platform" | "launchMode" | "serverType" | "favourites"
    | "sortAscending" | "languageOverride" |"devFlag">;

const initialState: LobbyState = {
    servers: [],
    games: [],
    realPlay: true,
    sortAscending: true,
    favourites: [],
    customer: "",
    server: "",
    platform: defaultPlatform,
    serverType: "local",
    launchMode: "self",
    affiliate: "",
    languageOverride: null,
    devFlag: false,
    showLobbyAppBarOptions: false,
}

export const lobbySlice = createSlice({
    name: "lobby",
    initialState,
    reducers: {
        changeRealPlay: (state, action: PayloadAction<boolean>) => {
            state.realPlay = action.payload;
        },
        changeDevFlag: (state, action: PayloadAction<boolean>) => {
            state.devFlag = action.payload;
        },
        changeServerType: (state, action: PayloadAction<string>) => {
            state.serverType = action.payload;
        },
        changeLaunchMode: (state, action: PayloadAction<GameLaunchMode>) => {
            state.launchMode = action.payload;
        },
        changePlatform: (state, action: PayloadAction<string>) => {
            state.platform = action.payload;
        },
        changeAffiliate: (state, action: PayloadAction<string>) => {
            state.affiliate = action.payload;
        },
        changeLanguageOverride: (state, action: PayloadAction<string | null>) => {
            state.languageOverride = action.payload;
        },
        changeEnvironment: (state, action: PayloadAction<Partial<EnvironmentOptions>>) => {
            const {server, customer} = action.payload;
            state.server = server;

            state.customer = customer;
            if (customer && server && (server !== state.server || customer !== state.customer)) {
                state.serverType = defaultServerTypeForServer(server);
                const defaultAffiliate = defaultAffiliateForCustomerServer(customer, server);
                const validAffiliates = state.servers?.find?.(({name}) => name === server)?.customers?.find?.(({name}) => name === customer)?.affiliates ?? [];
                if (defaultAffiliate && (validAffiliates.includes(defaultAffiliate) || !validAffiliates.length)) {
                    state.affiliate = defaultAffiliate;
                }
            }
        },
        changeGamesSortOrderAscending: (state, action: PayloadAction<boolean>) => {
            state.sortAscending = action.payload;
        },
        showLobbyAppBarOptions: (state, action:PayloadAction<boolean>) => {
            state.showLobbyAppBarOptions = action.payload;
        },
        searchGames: (state, action: PayloadAction<string>) => {
            state.search = action.payload;
        },
        clearGamesSearch: (state) => {
            state.search = undefined;
        },
        toggleGameFavourite: (state, action) => {
            const favouritesSet = new Set(state.favourites);
            if (favouritesSet.has(action.payload)) {
                favouritesSet.delete(action.payload);
            } else {
                favouritesSet.add(action.payload);
            }
            state.favourites = Array.from(favouritesSet);
        }
    },
    extraReducers: (builder) => builder
        .addCase<string, PayloadAction<Partial<PersistedLobbyState>>>("persist/load", (state, {payload}) => {
            const {
                customer,
                server,
                realPlay,
                affiliate,
                platform,
                launchMode,
                serverType,
                favourites,
                sortAscending,
                languageOverride,
                devFlag,
            } = payload;
            if (typeof customer === "string") {
                state.customer = customer;
            }
            if (typeof server === "string") {
                state.server = server;
            }
            if (typeof realPlay === "boolean") {
                state.realPlay = realPlay;
            }
            if (typeof affiliate === "string") {
                state.affiliate = affiliate;
            }
            if (typeof platform === "string") {
                state.platform = platform;
            }
            if (typeof launchMode === "string") {
                state.launchMode = launchMode as GameLaunchMode;
            }
            if (typeof serverType === "string") {
                state.serverType = serverType;
            }
            if (Array.isArray(favourites)) {
                state.favourites = favourites;
            }
            if (typeof sortAscending === "boolean") {
                state.sortAscending = sortAscending;
            }
            state.languageOverride = (languageOverride ?? null);
            if (typeof devFlag === "boolean") {
                state.devFlag = devFlag;
            }
        })
        .addMatcher(lobbyApi.endpoints.login.matchFulfilled, (state, {payload}) => {
            if (!state.customer) {
                state.customer = payload.defaultCustomer;
            }
        })
        .addMatcher(lobbyApi.endpoints.getGames.matchFulfilled, (state, {payload}) => {
            state.games = payload;
        })
        .addMatcher(lobbyApi.endpoints.getServers.matchFulfilled, (state, {payload}) => {
            state.servers = payload;
            if (!state.server) {
                state.server = payload[0]?.name;
            }
        })
});

export const {
    changeEnvironment,
    changeAffiliate,
    changeLanguageOverride,
    changeServerType,
    changeLaunchMode,
    changePlatform,
    changeRealPlay,
    changeGamesSortOrderAscending,
    showLobbyAppBarOptions,
    searchGames,
    clearGamesSearch,
    toggleGameFavourite,
    changeDevFlag,
} = lobbySlice.actions;

export const selectLobbyState = (state: RootState): LobbyState => state.lobby;
export const selectLobbyGames = (state: RootState) => state.lobby.games?.map(storedGameToGame) ?? undefined;
export const selectLobbyEnvironmentOptions = (state: RootState): Partial<EnvironmentOptions> => ({
    customer: state.lobby.customer,
    server: state.lobby.server,
})
export const selectLobbyEnvironmentIsValid = createSelector(
    selectLobbyEnvironmentOptions,
    ({customer, server}) => !!customer && !!server
)
export const selectGameLaunchOptions = (state: RootState): Partial<GameLaunchOptions & PerEnvironmentLaunchOptions> => ({
    realPlay: state.lobby.realPlay,
    platform: state.lobby.platform,
    serverType: state.lobby.serverType,
    launchMode: state.lobby.launchMode,
    affiliate: state.lobby.affiliate,
    languageOverride: state.lobby.languageOverride,
    devFlag: state.lobby.devFlag,
})
export const selectLobbyGameViewSortAscending = (state: RootState): boolean => state.lobby.sortAscending;
export const selectShowAppBarLobbyOptions = (state: RootState): boolean => state.lobby.showLobbyAppBarOptions;
export const selectLobbyGamesSearchString = (state: RootState): string | undefined => state.lobby.search;
export const selectLobbyGamesSortAscending = (state: RootState): boolean => state.lobby.sortAscending;
export const selectFavouriteGames = (state: RootState): number[] => state.lobby.favourites;
export const selectGameLaunchUsesLoader = createSelector(
    selectGameLaunchOptions,
    selectLobbyEnvironmentOptions,
    ({affiliate}, {server, customer}) => ((affiliate?.toLowerCase().includes("content") ?? false) && !server?.includes("dev") && customer !== "dyvo") || server?.trim?.() === "" || server === "demo",
);

export const selectGameById = (id: number) => createSelector(
    selectLobbyGames,
    (games) =>
        games.find((game) => game.id === id)
)

export const selectServers = (state: RootState): Server[] => state.lobby.servers;

export const selectCustomersForCurrentServer = createSelector(selectServers, selectLobbyEnvironmentOptions, (servers, environment) => {
    if(environment?.server) {
        const matchingServer = servers.find(({name}) => name === environment.server);

        if(matchingServer) {
            return matchingServer.customers ?? [];
        }
    }

    return [];
});

export const selectAffiliatesForCurrentCustomer = createSelector(selectServers, selectLobbyEnvironmentOptions, selectCustomersForCurrentServer, (servers, environment, customers) => {
    if(environment?.customer) {
        const matchingCustomer = customers.find(({name}) => name === environment.customer);

        if(matchingCustomer?.affiliates) {
            return matchingCustomer.affiliates?.map<LabelledValue>((affiliateName) => ({value: affiliateName, label: affiliateName}));
        }
        else {
            return [];
        }
    }

    return [];
})