import {Route, Routes, useLocation} from 'react-router-dom';
import LoginPage from '../pages/LoginPage';
import LobbyPage from '../pages/LobbyPage';
import React, {FunctionComponent} from 'react';
import {GamePage} from "../pages/GamePage";
import {RequireAuth} from "../components/auth-helpers/RequireAuth";
import {AdminRoutes} from "./AdminRoutes";
import {Error403Page, Error404Page} from '../pages/admin/ErrorPages';
import {LogoutPage} from '../pages/LogoutPage';
import {RouterPageLayout} from '../PageLayout';

export const LobbyRoutes: FunctionComponent = () => {
    const location = useLocation();
    return (
        <Routes>
            <Route element={<RouterPageLayout />}>
                <Route path="/" element={
                    <RequireAuth redirectTo="/login">
                        <LobbyPage/>
                    </RequireAuth>}
                />
            </Route>
            <Route element={<RouterPageLayout scrollable />}>
                <Route path="login" element={
                    <LoginPage/>
                }/>
                <Route path="logout" element={
                    <LogoutPage/>
                }/>
                <Route path="admin/*" element={
                    <AdminRoutes/>
                }/>
                <Route path="game/:id" element={
                    <GamePage/>
                }/>
                <Route path="403" element={
                    <Error403Page location={location}/>
                }/>
                <Route path="*" element={
                    <Error404Page/>
                }/>
            </Route>
        </Routes>
    )
}
