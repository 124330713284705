import {SingleStringSelectProps} from "../../../types/mui-convenience-props";
import {Autocomplete, AutocompleteRenderInputParams, CircularProgress, TextField} from "@mui/material";
import * as React from "react";


export interface AsyncStringSelectProps<DisableClearable extends boolean | undefined>
    extends Omit<SingleStringSelectProps<DisableClearable>, "renderInput" | "options" | "loading"> {
    /**
     * Label for the component
     */
    label: string;
    /**
     * Options array when loaded (or undefined when it's still loading)
     */
    options: string[] | undefined;
    /**
     * Are the options currently loading?
     */
    loading?: boolean;
    /**
     * Callback for when the value changes
     */
    onValueChange: (newValue: DisableClearable extends true ? string : string | null) => void;
}

/**
 * MUI {@link Autocomplete} set up to accept an async source of string options
 *
 * Can also be used with a non-async source
 *
 * This is a convenience component based on the [MUI docs]{@link https://mui.com/components/autocomplete/#asynchronous-requests}
 */
export const AsyncStringSelect = <DisableClearable extends boolean | undefined>({
                                                                                    label,
                                                                                    options,
                                                                                    loading,
                                                                                    value,
                                                                                    onValueChange,
                                                                                    ...props
                                                                                }: AsyncStringSelectProps<DisableClearable>) => {

    const handleChange: AsyncStringSelectProps<DisableClearable>["onChange"] = (event, value) => {
        onValueChange(value);
    }

    return (
        <Autocomplete
            {...props}
            options={options ?? []}
            value={value}
            onChange={handleChange}
            loading={loading}
            renderInput={(params: AutocompleteRenderInputParams) => (
                <TextField
                    {...params}
                    label={label}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loading ? <CircularProgress color="inherit" size={20}/> : null}
                                {params.InputProps.endAdornment}
                            </>
                        )
                    }}
                />
            )}
        />
    )
}
