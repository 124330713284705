import React, {FunctionComponent, lazy} from "react";
import {Route, Routes} from "react-router-dom";
import {RequireAdminAccess} from "../components/auth-helpers/RequireAuth";
import {LobbySuspense} from "../PageLayout";

const AdminAccountsRoutes = lazy(() => import("./AdminAccountsRoutes"));
const AdminGamesRoutes = lazy(() => import("./AdminGamesRoutes"));
const ExclusionListPage = lazy(() => import("../pages/admin/ExclusionListPage"));
const AdminGameSetsRoutes = lazy(() => import("./AdminGameSetsRoutes"));
const AdminTabsRoutes = lazy(() => import("./AdminTabsRoutes"));

export const AdminRoutes: FunctionComponent = () => {
    return (
        <Routes>
            <Route
                path="/users/*"
                element={
                    <RequireAdminAccess
                        page={"users"}
                        redirectTo="/403"
                        noHistoryOnRedirect
                    >
                        <LobbySuspense>
                            <AdminAccountsRoutes />
                        </LobbySuspense>
                    </RequireAdminAccess>
                }
            />
            <Route
                path="/games/*"
                element={
                    <RequireAdminAccess
                        page={"games"}
                        redirectTo={"/403"}
                        noHistoryOnRedirect
                    >
                        <LobbySuspense>
                            <AdminGamesRoutes />
                        </LobbySuspense>
                    </RequireAdminAccess>
                }
            />
            <Route
                path="/exclusions/*"
                element={
                    <RequireAdminAccess
                        page={"exclusions"}
                        redirectTo="/403"
                        noHistoryOnRedirect
                    >
                        <LobbySuspense>
                            <ExclusionListPage />
                        </LobbySuspense>
                    </RequireAdminAccess>
                }
            />
            <Route
                path="/gameSets/*"
                element={
                    <RequireAdminAccess
                        page={"games"}
                        redirectTo="/403"
                        noHistoryOnRedirect
                    >
                        <LobbySuspense>
                            <AdminGameSetsRoutes />
                        </LobbySuspense>
                    </RequireAdminAccess>
                }
            />
            <Route
                path="/tabs/*"
                element={
                    <RequireAdminAccess
                        page={"games"}
                        redirectTo={"/403"}
                        noHistoryOnRedirect
                    >
                        <LobbySuspense>
                            <AdminTabsRoutes />
                        </LobbySuspense>
                    </RequireAdminAccess>
                }
            />
        </Routes>
    )
}
