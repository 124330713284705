import React, {FunctionComponent} from "react";
import {useAppDispatch, useAppSelector} from "../../../store/hooks/root-hooks";
import {changeEnvironment, EnvironmentOptions, selectLobbyEnvironmentOptions} from "../../../store/slices/lobbySlice";
import {EnvironmentSelect} from "./EnvironmentSelect";

export const LobbyEnvironmentOptions: FunctionComponent = () => {
    const dispatch = useAppDispatch();
    const {customer, server} = useAppSelector(selectLobbyEnvironmentOptions);

    const handleEnvironmentChanged = (value: Partial<EnvironmentOptions>) => {
        dispatch(changeEnvironment(value));
    }

    return (
        <EnvironmentSelect
            value={{customer, server}}
            doPartialUpdates
            onEnvironmentChange={handleEnvironmentChanged}
        />
    )
}


