export interface CustomerServerMap {
    [index: string] : {
        [index: string]: CustomerServerConfig
        default: CustomerServerConfig
    }
}

export interface CustomerServerConfig {
    server: string;
    defaultAffiliate?: string;
}

export let customerServerMap : CustomerServerMap = {
    "blueprint": {
        "default": {server: "https://blueprintrgsst.blueprintgaming.com/", defaultAffiliate: "direct"},
        "rgsst": {server: "https://blueprintrgsst.blueprintgaming.com/", defaultAffiliate: "direct"},
        "rgsuat": {server: "https://blueprintrgsuat.blueprintgaming.com/",  defaultAffiliate: "content"},
        "rgsdev1": {server: "http://bpg-rgs-dev1.blueprintgaming.com/",  defaultAffiliate: "direct"},
        "rgscdev": {server: "http://bpg-rgs-dev1.blueprintgaming.com/",  defaultAffiliate: "direct"},
        "rgspubst": {server: "https://blueprintrgspubst.blueprintgaming.com/",  defaultAffiliate: "direct"},
        "rgsdev2": {server: "http://bpg-rgs-dev2.blueprintgaming.com/",  defaultAffiliate: "direct"},
        "rgsdev3": {server: "http://bpg-rgs-dev3.blueprintgaming.com/", defaultAffiliate: "direct"},
        "demo" : {server : "https://customerlobbyrgspubst.blueprintgaming.com/", defaultAffiliate : "content"}
    },
    "gli": {
        "default": {server: "https://nmirgsst.blueprintgaming.com/",  defaultAffiliate: "direct"},
        "rgsst": {server: "https://nmirgsst.blueprintgaming.com/",  defaultAffiliate: "direct"},
    },
    "dyvo": {
        "default": {server: "https://contentrgs.dyvogaming.com/",  defaultAffiliate: "dyvo"},
        "rgspubst": {server: "https://contentrgs.dyvogaming.com/",  defaultAffiliate: "dyvo"},
    }
};


if(!window.location.origin.includes("demo")){
    fetch("./server-maps.json").then(async (result) => {
        const parsed = await result.json();
        customerServerMap = Object.assign(customerServerMap, parsed);
    })
}



export const serverConfigForCustomerServer = (customerName: string, serverName: string) => {
    const serverMap = customerServerMap[customerName] ?? customerServerMap["blueprint"];
    return (serverMap[serverName] ?? serverMap["default"])
}
export const defaultAffiliateForCustomerServer = (customerName: string, serverName: string) => {
    return serverConfigForCustomerServer(customerName, serverName).defaultAffiliate;
}

export const serversWithLoaderSupport = ["rgsuat"];
export const platforms = ["desktop", "mobile"];
export const defaultPlatform = navigator.userAgent.match(/(android|i(Pad|Phone|OS))/gi) ? "mobile" : "desktop";
export const serverTypes = ["live", "staging", "uat", "dev", "local"];
export const defaultServerTypeForServer = (serverName?: string) => {
    switch(serverName) {
        case "rgsst":
        case "rgspubst":
            return "staging";
        case "rgsuat":
            return "uat";
        case "rgsdev1":
        case "rgsdev2":
        case "rgsdev3":
        case "rgscdev":
            return "dev";
        default:
            return "local";
    }
}

