import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import {App} from "./App";
import "./index.css"
import {store} from './store/store';
import {Provider} from 'react-redux';
import {ConfigLoader} from './components/ConfigLoader';

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <ConfigLoader>
                {(config) => (
                    <App config={config}/>
                )}
            </ConfigLoader>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root'));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

