import {useMediaQuery} from '@mui/material';

export interface Breakpoints {
    isNarrow: boolean;
    isPortrait: boolean;
}

export function useBreakpoints(): Breakpoints {
    const isNarrow = useMediaQuery("(max-width: 425px)");
    const isPortrait = useMediaQuery("(orientation: portrait)");

    return {
        isNarrow, isPortrait
    }
}
