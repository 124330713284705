/**
 * General labelled value returned by misc endpoints like /Info/ for things like Affiliates/Currencies/Languages/etc
 */
export interface LabelledValue {
    label: string;
    value: string;
}

export function isLabelledValue(arg: unknown): arg is LabelledValue {
    const labelledValue = arg as Partial<LabelledValue>;
    return typeof labelledValue?.label === "string" && typeof labelledValue?.value === "string";
}