import React, {FunctionComponent} from 'react';
import {AppBarSearch} from '../common/inputs/AppBarSearch';
import {ToggleIconButton} from '../common/inputs/ToggleIconButton';
import {useAppDispatch, useAppSelector} from '../../store/hooks/root-hooks';
import {changeGamesSortOrderAscending, searchGames, selectLobbyGamesSortAscending} from '../../store/slices/lobbySlice';
import {SortAlphabeticalAscending, SortAlphabeticalDescending} from 'mdi-material-ui';
import {Divider} from '@mui/material';

export const LobbyAppBarOptions: FunctionComponent = () => {
    const dispatch = useAppDispatch();
    const sortAscending = useAppSelector(selectLobbyGamesSortAscending);

    const onToggleSortOrder = (value: boolean) => {
        dispatch(changeGamesSortOrderAscending(value));
    }
    const onSearchChange = (value?: string) => {
        dispatch(searchGames(value ?? ""))
    }

    return (
        <>
            <AppBarSearch
                onSearch={onSearchChange}
            />
            <ToggleIconButton
                value={sortAscending}
                iconTrue={<SortAlphabeticalAscending/>}
                iconFalse={<SortAlphabeticalDescending/>}
                onChange={onToggleSortOrder}
                sx={{
                    opacity: 0.8,
                }}
            />
            <Divider orientation="vertical"/>
        </>
    )
}
